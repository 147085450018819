<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <!-- <p class="ml-3 colorBlue fw-b fs-xl">{{ item.title }}</p> -->
        <p class="colorBlue fw-b fs-xl my-text">{{ item.title }}</p>
        <div>
          <!-- <dv-digital-flop
            class="my-class dv-dig-flop ml-2 mt-2 pl-6"
            :config="item.number"
          /> -->
          <dv-digital-flop
            class="my-class dv-dig-flop pl-6"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <!-- <span class="fs-xl text mx-2 mb-1 pl-3 my-text">水泵运行记录</span> -->
        <span v-if="pumpNum > 1" class="fs-xl text mx-2 mb-1 pl-3 my-text">水泵运行记录: {{pumpNum}} - {{curPumpNo}}</span>
        <span v-else class="fs-xl text mx-2 mb-1 pl-3 my-text">水泵运行记录</span>
        <dv-scroll-ranking-board class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>营养指数</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>水温</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :unit="rate[1].unit"
            :colorObj="rate[1].colorData"
          />
        </div>
        <div class="water">
          <dv-water-level-pond class="dv-wa-le-po" :config="water" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChartRate'
import { formatTime } from '../utils/index.js'
import { getMinutes } from '../utils/index.js'
import Transfer from "../utils/transfer";

//工程模组 水泵运行记录
var moduleDataArr = [[],[],[],[],[],[],[],[],[],[]];
//工程模组 传感器当前数据
//1 主板数据 2~11 子系统数据
var moduleSensorDataArr = [{},{},{},{},{},{},{},{},{},{},{}];
export default {
  data() {
    return {
      timing: null,
      pumpNum: 1,
      curPumpNo: 1,
      titleItem: [
        {
          title: '环境温度(℃)',
          number: {
            number: [20],
            toFixed: 1,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '环境湿度(%)',
          number: {
            number: [15],
            toFixed: 1,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '光照强度(LUX)',
          number: {
            number: [60],
            toFixed: 1,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '空气质量',
          number: {
            number: [6],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '二氧化碳浓度(ppm)',
          number: {
            number: [6],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '酸碱度',
          number: {
            number: [6],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        }
      ],
      ranking: {
        data: [
          {
            name: '运行',
            value: 5
          },
          {
            name: '停止',
            value: 30
          },
          {
            name: '运行',
            value: 5
          },
          {
            name: '停止',
            value: 30
          }
        ],
        waitTime: 2000,
        carousel: 'single',
        unit: '分',
        sort: false
      },
      water: {
        data: [20],
        shape: 'roundRect',
        formatter: '{value}cm',
        waveNum: 1
      },
      // 营养指数和水温
      rate: [
        {
          id: 'centerRate1',
          tips: 60,
          colorData: {
            unit: '%',
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 40,
          colorData: {
            unit: '℃',
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ]
    }
  },
  components: {
    CenterChart
  },
  mounted() {
    var globalData = this.$store.state.globalData;
    // this.pumpNum = globalData.pumpNum;
    // this.curPumpNo = globalData.curPumpNo;
    // if(globalData.curProtocolType == 2 && this.pumpNum > 1) {
    //   this.changeTiming();
    // }
    
    // if(globalData.curProtocolType == 2) {
    //   //工程模组协议
    //   // this.getSensorData();
    //   this.getSensorDataMulti();
    //   // this.getPumpHistory();
    //   this.getPumpHistoryMulti();
    // } else {
    //   //家用机协议
    //   this.getMachineDetail();
    //   this.getPumpWorkStatus();
    // }
    
    Transfer.$on('center', (msg) => {
      this.pumpNum = globalData.pumpNum;
      this.curPumpNo = globalData.curPumpNo;
      //初始化数据
      moduleDataArr = [[],[],[],[],[],[],[],[],[],[]];
      moduleSensorDataArr = [{},{},{},{},{},{},{},{},{},{},{}];

      //console.log(msg);
      if(globalData.curProtocolType == 2) {
        if(this.pumpNum > 1) {
          //停止定时器
          clearInterval(this.timing);
          this.timing = null;
          //重新启动定时器
          this.changeTiming();
        }
        
        //工程模组协议
        // this.getSensorData();
        this.getSensorDataMulti();
        // this.getPumpHistory();
        this.getPumpHistoryMulti();
      } else {
        //家用机协议
        this.getMachineDetail();
        this.getPumpWorkStatus();
      }
    });

    Transfer.$on('center-child', (msg) => {
      //环境传感器
      if(globalData.envSensorNum > 1) {
        var index = globalData.curEnvSensorNo;
        var arr = moduleSensorDataArr[index];
        this.getSensorDataFunEnv(this, arr);
      }
      //液体传感器
      if(globalData.waterSensorNum > 1) {
        var index = globalData.curWaterSensorNo;
        var arr = moduleSensorDataArr[index];
        this.getSensorDataFunWater(this, arr);
      }
    });
  },
  beforeDestroy () {
    clearInterval(this.timing);
    this.timing = null;
  },
  methods: {
    //水泵轮询定时
    changeTiming() {
      var globalData = this.$store.state.globalData;
      this.timing = setInterval(() => {
        if(globalData.curProtocolType == 2 && this.pumpNum > 1) {
          if(this.curPumpNo >= this.pumpNum) {
            this.curPumpNo = 1;
          } else {
            this.curPumpNo += 1;
          }
          // globalData.curPumpNo = this.curPumpNo;
          this.$store.commit("setCurPumpNo", this.curPumpNo);

          // var index = globalData.curPumpNo;
          var index = this.curPumpNo;
          var arr = moduleDataArr[index - 1];

          this.ranking.data = arr;
          this.ranking = { ...this.ranking };

         
          if(globalData.webcamShow != 1) {
            //刷新工程模组水泵状态
            Transfer.$emit('centerRight3-view','msg-centerRight3');
          }
          
        }
      }, this.$GLOBAL.deviceChildShowTiming);
      
    },
    
    //家用机
    getMachineDetail() {
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;

      let that = this;
      //获取设备详情
      var curMachineId = globalData.curMachine;
      var resData = curMachineId;
      this.$httpApi.getMachineDetail(resData, function(data) {
        // console.log(JSON.stringify(data));
        if(data == null) return;

        that.titleItem[0].number.number[0] = (data.statusAirTemperature == null ? 0 : data.statusAirTemperature);
        that.titleItem[1].number.number[0] = (data.statusAirHumidity == null ? 0 : data.statusAirHumidity);
        that.titleItem[2].number.number[0] = (data.statusIllumination == null ? 0 : data.statusIllumination);
        that.titleItem[3].number.number[0] = (data.statusAirQuality == null ? 0 : data.statusAirQuality);
        that.titleItem[4].number.number[0] = (data.statusCO2 == null ? 0 : data.statusCO2);
        that.titleItem[5].number.number[0] = (data.statusPH == null ? 0 : data.statusPH);
        
        that.rate[0].tips = (data.statusNutrientIndex == null ? 0 : data.statusNutrientIndex);
        if(that.rate[0].tips > 100) that.rate[0].tips = 100;

        that.rate[1].tips = (data.statusWaterTemperature == null ? 0 : data.statusWaterTemperature);
        that.water.data[0] = (data.statusWaterLevel == null ? 0 : (data.statusWaterLevel / 10));
        
        that.titleItem[0].number = { ...that.titleItem[0].number };
        that.titleItem[1].number = { ...that.titleItem[1].number };
        that.titleItem[2].number = { ...that.titleItem[2].number };
        that.titleItem[3].number = { ...that.titleItem[3].number };
        that.titleItem[4].number = { ...that.titleItem[4].number };
        that.titleItem[5].number = { ...that.titleItem[5].number };
        
        that.rate = { ...that.rate };
        that.water = { ...that.water };

         //一代机获取数据后，如果没有摄像头，需刷新水泵状态
        if(globalData.webcamShow != 1) {
            that.$store.commit("setCurPumpStatus", (data.controlPump == 1 ? 1 : 0));
            Transfer.$emit('centerRight3','msg-centerRight3');
          }
      }, function(xhr) {
        console.log(xhr.responseText);
      });

    },
    //工程模组-二代机 数据保存
    getSensorDataSave(curNo, data) {
      moduleSensorDataArr[curNo - 1] = data;
    },
    //工程模组-二代机 渲染页面 环境传感器
    getSensorDataFunEnv(that, data) {
      that.titleItem[0].number.number[0] = ((data.innerTemp == null || data.innerTemp == undefined) ? 0 : data.innerTemp);
      that.titleItem[1].number.number[0] = ((data.innerHumidity == null || data.innerHumidity == undefined) ? 0 : data.innerHumidity);
      that.titleItem[2].number.number[0] = ((data.innerBright == null || data.innerBright == undefined) ? 0 : data.innerBright);
      that.titleItem[3].number.number[0] = ((data.airQuality == null || data.airQuality == undefined) ? 0 : data.airQuality);
      that.titleItem[4].number.number[0] = ((data.innerCO2 == null || data.innerCO2 == undefined) ? 0 : data.innerCO2);
      that.titleItem[5].number.number[0] = ((data.statusPH == null || data.statusPH == undefined) ? 0 : data.statusPH);

      that.titleItem[0].number = { ...that.titleItem[0].number };
      that.titleItem[1].number = { ...that.titleItem[1].number };
      that.titleItem[2].number = { ...that.titleItem[2].number };
      that.titleItem[3].number = { ...that.titleItem[3].number };
      that.titleItem[4].number = { ...that.titleItem[4].number };
      that.titleItem[5].number = { ...that.titleItem[5].number };
    },
    //工程模组-二代机 渲染页面 液体传感器
    getSensorDataFunWater(that, data) {
      that.rate[0].tips = ((data.statusEC == null || data.statusEC == undefined) ? 0 : data.statusEC);
      if(that.rate[0].tips > 100) that.rate[0].tips = 100;

      that.rate[1].tips = ((data.waterTemp == null || data.waterTemp == undefined) ? 0 : data.waterTemp);
      that.water.data[0] = ((data.waterLevel == null || data.waterLevel == undefined) ? 0 : data.waterLevel);
      
      that.rate = { ...that.rate };
      that.water = { ...that.water };
    },
    //工程模组-二代机
    getSensorData(that, globalData, groupId, curNo, resData, isEnvSensor, isWaterSensor){
      // var globalData = this.$store.state.globalData;
      // if(globalData.curMachine == '') return;
      
      // let that = this;
      // var groupId = 1;
      // var curMachineId = globalData.curMachine;
      // var resData = "?groupId=" + groupId + "&machineId=" + curMachineId;
      this.$httpApi.getMacineSensor(resData, function(d) {
        // console.log(JSON.stringify(d)); 

        if(d == null || d.message == null) {
          // that.getSensorDataSave(groupId, {});
          that.getSensorDataSave(curNo, {});
          return;
        }
        var data = d.message;
        //保存数据
        // that.getSensorDataSave(groupId, data);
        that.getSensorDataSave(curNo, data);
        
        //渲染页面-环境传感器
        if(isEnvSensor) {
          if(curNo == globalData.curEnvSensorNo) {
            that.getSensorDataFunEnv(that, data);
          }
        }
        //渲染页面-液体传感器
        if(isWaterSensor) {
          if(curNo == globalData.curWaterSensorNo) {
            that.getSensorDataFunWater(that, data);
          }
        }

        // //渲染页面-环境传感器
        // if((globalData.envSensorNum > 1 && groupId == 2) || 
        //     (globalData.envSensorNum <= 1 && groupId == 1)) {
        //   that.getSensorDataFunEnv(that, data);
        // }
        
        // //渲染页面-液体传感器
        // if((globalData.waterSensorNum > 1 && groupId == 2) || 
        //     (globalData.waterSensorNum <= 1 && groupId == 1)) {
        //   that.getSensorDataFunWater(that, data);
        // }
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });
      
    },
    //工程模组-二代机
    getSensorDataMulti() {
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;
      
      let that = this;
      var curMachineId = globalData.curMachine;
      var groupId = 1, envGroupId = 1, waterGroupId = 1;
      
      //工程模组
      if(globalData.curStyleSize == 7 || globalData.curStyleSize == 8) {
        //最大
        var maxSensorNum = globalData.waterSensorNum;
        if(maxSensorNum < globalData.envSensorNum) {
          maxSensorNum = globalData.envSensorNum;
        }
        
        for(var i = 1; i <= maxSensorNum; i++) {
          envGroupId = 0;
          waterGroupId = 0;
          if(i <= globalData.envSensorNum) {
            envGroupId = globalData.envSensorList[i - 1];
            envGroupId++;
          }
          if(i <= globalData.waterSensorNum) {
            waterGroupId = globalData.waterSensorList[i - 1];
            waterGroupId++;
          }
          
          //获取传感器数据
          if(envGroupId == waterGroupId) {
            if(envGroupId == 0) continue;

            //总的传感器数据
            groupId = envGroupId;
            var resData = "?groupId=" + groupId + "&machineId=" + curMachineId;
            this.getSensorData(that, globalData, groupId, i, resData, true, true);
          } else {
            //环境传感器数据
            if(envGroupId > 0) {
              var resData = "?groupId=" + envGroupId + "&machineId=" + curMachineId;
              this.getSensorData(that, globalData, envGroupId, i, resData, true, false);
            }
           
            //液体传感器数据
            if(waterGroupId > 0) {
              var resData = "?groupId=" + waterGroupId + "&machineId=" + curMachineId;
              this.getSensorData(that, globalData, waterGroupId, i, resData, false, true);
            }
            
          }
        }
      } else {
        //获取主板数据
        var resData = "?groupId=" + groupId + "&machineId=" + curMachineId;
        this.getSensorData(that, globalData, groupId, 1, resData, true, true);
      }
      
      
      // //最大
      // var maxSensorNum = globalData.waterSensorNum;
      // if(maxSensorNum < globalData.envSensorNum) {
      //   maxSensorNum = globalData.envSensorNum;
      // }
      // //是否获取主板数据
      // var getMainSensor = false;
      // if(globalData.envSensorNum <= 1 || globalData.waterSensorNum <= 1) {
      //   getMainSensor = true;
      // }

      // if(maxSensorNum > 1) {
      //   if(getMainSensor == true) {
      //     var resData = "?groupId=" + groupId + "&machineId=" + curMachineId;
      //     this.getSensorData(that, globalData, groupId, resData);
      //   }
      //   for(var i = 1; i <= maxSensorNum; i++) {
      //     groupId = i + 1;
      //     var resData = "?groupId=" + groupId + "&machineId=" + curMachineId;
      //     this.getSensorData(that, globalData, groupId, resData);
      //   }
      // } else {
      //   var resData = "?groupId=" + groupId + "&machineId=" + curMachineId;
      //   this.getSensorData(that, globalData, groupId, resData);
      // }
      
    },
    //获取水泵记录数据-一代家用机
    getPumpWorkStatus() {
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;

      let that = this;
      var curMachineId = globalData.curMachine; //"872204150001";
      var column = "controlPump";
      var curData = formatTime(new Date(), 'yyyy-MM-dd');  //"2022-06-18"; //
      var resData = "?machineId=" + curMachineId + "&column=" + column + "&start=" + curData + "&end=" + curData;
      this.$httpApi.getMachinePumpWorkStatus(resData, function(data) {
        // console.log(JSON.stringify(data));
        if(data==null || data.length==0)  {
          that.ranking.data = [];
          that.ranking = { ...that.ranking };
          return;
        }

        var arr = [];
        for(var i = 0; i < data.length; i++) {
          var item = data[i];
          var arrItem = {};
          arrItem.name = (item.statusValue == 1 ? '运行' : '关闭');
          arrItem.value = getMinutes(item.startCollectTime, item.endCollectTime);
          arr[i] = arrItem;

          if((i + 1) >= 10) break;
        }
        that.ranking.data = arr;

        that.ranking = { ...that.ranking };
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });

    },
    //水泵记录数据存储-二代机
    getPumpHistorySave(that, curNo, arr) {
      moduleDataArr[curNo - 1] = arr;
    },
    //水泵记录渲染页面-二代机
    getPumpHistoryFun(that, arr) {
      that.ranking.data = arr;
      that.ranking = { ...that.ranking };
    },
    //获取水泵记录-二代机
    getPumpHistory(that, globalData, curNo, resData){
      // var globalData = this.$store.state.globalData;
      // if(globalData.curMachine == '') return;
      
      // let that = this;
      // // { value: 'pump11Switch', text: '主水泵1' },
      // // { value: 'pump12Switch', text: '主水泵2' },
      // // { value: 'pump1Switch', text: '水泵1' },
      // // { value: 'pump2Switch', text: '水泵2' },
      // // { value: 'pump3Switch', text: '水泵3' },
      // // { value: 'pump4Switch', text: '水泵4' },
      // // { value: 'pump5Switch', text: '水泵5' },
      // // { value: 'pump6Switch', text: '水泵6' },
      // // { value: 'pump7Switch', text: '水泵7' },
      // // { value: 'pump8Switch', text: '水泵8' },
      // // { value: 'pump9Switch', text: '水泵9' },
      // // { value: 'pump10Switch', text: '水泵10' }
      // var curMachineId = globalData.curMachine; //"872204150001";
      // var column = "pump1Switch";
      // if(globalData.curStyleSize >= 1 && globalData.curStyleSize <= 6) {
      //   column = "pumpSwitch";
      // }
      // var curData = formatTime(new Date(), 'yyyy-MM-dd');  //"2022-06-18"; //
      // var resData = "?machineId=" + curMachineId + "&column=" + column + "&start=" + curData + "&end=" + curData;
      this.$httpApi.getMachinePumpHistory(resData, function(data) {
        // console.log(JSON.stringify(data)); 
        if(data == null || data.length == 0) {
          that.getPumpHistorySave(that, curNo, []);
          that.getPumpHistoryFun(that, []);
          return;
        }
        
        var arr = [];
        for(var i = 0; i < data.length; i++) {
          var item = data[i];
          var arrItem = {};
          arrItem.name = (item.statusValue == 2 ? '运行' : '关闭');
          arrItem.value = getMinutes(item.startCollectTime, item.endCollectTime);
          arr[i] = arrItem;

          if((i + 1) >= 10) break;
        }
        //存储数据
        that.getPumpHistorySave(that, curNo, arr);
        
        //页面渲染  初次加载设备数据，只渲染第一次获取的数据
        if(curNo == globalData.curPumpNo) {
          that.getPumpHistoryFun(that, arr);
        }
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });
      
    },
    //获取水泵记录-二代机
    getPumpHistoryMulti(){
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;
      
      let that = this;
      // { value: 'pump11Switch', text: '主水泵1' },
      // { value: 'pump12Switch', text: '主水泵2' },
      // { value: 'pump1Switch', text: '水泵1' },
      // { value: 'pump2Switch', text: '水泵2' },
      // { value: 'pump3Switch', text: '水泵3' },
      // { value: 'pump4Switch', text: '水泵4' },
      // { value: 'pump5Switch', text: '水泵5' },
      // { value: 'pump6Switch', text: '水泵6' },
      // { value: 'pump7Switch', text: '水泵7' },
      // { value: 'pump8Switch', text: '水泵8' },
      // { value: 'pump9Switch', text: '水泵9' },
      // { value: 'pump10Switch', text: '水泵10' }
      var curMachineId = globalData.curMachine; //"872204150001";
      var curData = formatTime(new Date(), 'yyyy-MM-dd');  //"2022-06-18"; //

      
      //工程模组
      if(globalData.curStyleSize == 7 || globalData.curStyleSize == 8) {
        for(var i = 1; i <= globalData.pumpNum; i++) {
          var pumpIndex = globalData.pumpList[i-1];
          var column = "pump" + pumpIndex + "Switch";
          var resData = "?machineId=" + curMachineId + "&column=" + column + "&start=" + curData + "&end=" + curData;
          this.getPumpHistory(that, globalData, i, resData);
        }
      } else {
        var column = "pumpSwitch";
        var resData = "?machineId=" + curMachineId + "&column=" + column + "&start=" + curData + "&end=" + curData;
        this.getPumpHistory(that, globalData, 1, resData);
      }

      // var column = "pump1Switch";
      // if(globalData.curStyleSize >= 1 && globalData.curStyleSize <= 6) {
      //   column = "pumpSwitch";
      // } else if(globalData.pumpNum > 1) {
      //   for(var i = 1; i <= globalData.pumpNum; i++) {
      //     column = "pump" + i + "Switch";
      //     var resData = "?machineId=" + curMachineId + "&column=" + column + "&start=" + curData + "&end=" + curData;
      //     this.getPumpHistory(that, i, resData);
      //   }
      //   return;
      // }
      
      // var resData = "?machineId=" + curMachineId + "&column=" + column + "&start=" + curData + "&end=" + curData;
      // this.getPumpHistory(that, 1, resData);
    }
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;

  .my-text {
    font-size: 15px;
  }
  
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      text-align: center;
      .my-class {
        margin: 8px auto;
      }
      .dv-dig-flop {
        width: 100px;
        height: 30px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        // height: 225px;
        height: 212px;
      }
      
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
