<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import { formatTime } from '../../../../utils/index.js'
import Transfer from "../../../../utils/transfer.js";

//工程模组 液体传感器
//1 主板数据 2~11 子系统数据
var moduleDataArr = [{},{},{},{},{},{},{},{},{},{},{}];
export default {
  data () {
    return {
      drawTiming: null,
      cdata: {
        year: null,
        timeCategory: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
        radarData: [],
        radarDataAvg: [],
        maxData: 100,
        sensorMaxData: [],
        sensorLineData: [32.6,32.6,32.6,32.6,32.5,32.4,32.4,32.6,35,33.5,34.6,33.4,35,34.8,33.7,32.7,31.9,30.6],
        sensorData: [[32.6,32.6,32.6,32.6,32.5,32.4,32.4,32.6,35,33.5,34.6,33.4,35,34.8,33.7,32.7,31.9,30.6],
                    [58,58,58,57,57,58,58,57,54,50,51,48,51,53,52,44,43,44],
                    [0,0,0,0,0,0,19,48,71,216,241,244,262,220,273,180,201,180]],
        sensorYText: '营养指数(%)',
        maxDataArr: [100, 100, 300],
        // sensorLineData: [],
        // sensorData: [],
        // sensorYText: '',
        // maxDataArr: [],
        sensorYArr: ['营养指数(%)', '水温(℃)', '水位(cm)'],
        curSensorIndex: 0  //0:环境温度, 1:环境湿度， 2：光照强度
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
    var globalData = this.$store.state.globalData;
    // if(globalData.curProtocolType == 2) {
    //   //工程模组协议
    //   // this.getSensorHistoryData();
    //   this.getSensorHistoryDataMulti();
    // } else {
    //   //家用机协议
    //   this.getSensorHistoryDataWithHour();
    // }
    this.drawTimingFn();
    Transfer.$on('bottomRight-bottomRight', (msg) => {
      // console.log(msg);
      if(globalData.curProtocolType == 2) {
        //工程模组协议
        // this.getSensorHistoryData();
        this.getSensorHistoryDataMulti();
      } else {
        //家用机协议
        this.getSensorHistoryDataWithHour();
      }
    });

    //切换子传感器
    Transfer.$on('bottomRight-bottomRight-child', (msg) => {
      if(globalData.waterSensorNum > 1) {
        var index = globalData.curWaterSensorNo;
        var datas = moduleDataArr[index];
        this.getSensorHistoryDataFun(this, datas)
      }
    });

  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
    this.drawTiming = null;
  },
  methods: {
     drawTimingFn () {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$GLOBAL.deviceChildSensorShowTiming);
    },
    setData () {
      // 折线图数据
      this.cdata.curSensorIndex++;
      if(this.cdata.curSensorIndex >= 3) {
        this.cdata.curSensorIndex = 0;
      }
      this.cdata.maxData = this.cdata.maxDataArr[this.cdata.curSensorIndex];
      // this.cdata.sensorMaxData = this.cdata.maxDataArr[this.cdata.curSensorIndex];
      this.cdata.sensorLineData = this.cdata.sensorData[this.cdata.curSensorIndex];
      this.cdata.sensorYText = this.cdata.sensorYArr[this.cdata.curSensorIndex];

      // 雷达图数据
      //   // 我的指标
      //   let averageSpeed = +(Math.random() * 5 + 3).toFixed(3);
      //   let maxSpeed = averageSpeed + +(Math.random() * 3).toFixed(2);
      //   let hour = +(distance / 1000 / averageSpeed).toFixed(1);
      //   let radarDayData = [distance, averageSpeed, maxSpeed, hour];
      //   this.cdata.radarData.unshift(radarDayData);


      // // 雷达图数据
      // this.cdata.radarData = [];
      // this.cdata.radarDataAvg = [];

      // let distance = Math.round(Math.random() * 100 + 50);
      // let averageSpeed = +(Math.random() * 5 + 3).toFixed(3);
      // let maxSpeed = averageSpeed + +(Math.random() * 3).toFixed(2);
      // let hour = +(distance / 10 / averageSpeed).toFixed(1);
      // let radarDayData = [distance, averageSpeed, maxSpeed, hour];
      // this.cdata.radarData.unshift(radarDayData);

      // let distanceAvg = Math.round(Math.random() * 100 + 40);
      // let averageSpeedAvg = +(Math.random() * 4 + 4).toFixed(3);
      // let maxSpeedAvg = averageSpeedAvg + +(Math.random() * 2).toFixed(2);
      // let hourAvg = +(distance / 10 / averageSpeed).toFixed(1);
      // let radarDayDataAvg = [
      //   distanceAvg,
      //   averageSpeedAvg,
      //   maxSpeedAvg,
      //   hourAvg
      // ];
      // this.cdata.radarDataAvg.unshift(radarDayDataAvg);

      // // 清空轮询数据
      // //this.cdata.timeCategory = [];
      // this.cdata.sensorMaxData = [];
      // this.cdata.sensorLineData = [];
      // this.cdata.radarData = [];
      // this.cdata.radarDataAvg = [];

      // let dateBase = new Date();
      // this.cdata.year = dateBase.getFullYear();
      // // 周数据
      // for (let i = 0; i < 7; i++) {
      //   // 日期
      //   //let date = new Date();
      //   //this.cdata.timeCategory.unshift([date.getMonth() + 1, date.getDate()-i].join("/"));

      //   // 折线图数据
      //   // this.cdata.sensorMaxData.push(this.cdata.maxData);
      //   let distance = Math.round(Math.random() * 11000 + 500);
      //   // this.cdata.sensorLineData.push(distance);

      //   // 雷达图数据
      //   // 我的指标
      //   let averageSpeed = +(Math.random() * 5 + 3).toFixed(3);
      //   let maxSpeed = averageSpeed + +(Math.random() * 3).toFixed(2);
      //   let hour = +(distance / 1000 / averageSpeed).toFixed(1);
      //   let radarDayData = [distance, averageSpeed, maxSpeed, hour];
      //   this.cdata.radarData.unshift(radarDayData);

      //   // 平均指标
      //   let distanceAvg = Math.round(Math.random() * 8000 + 4000);
      //   let averageSpeedAvg = +(Math.random() * 4 + 4).toFixed(3);
      //   let maxSpeedAvg = averageSpeedAvg + +(Math.random() * 2).toFixed(2);
      //   let hourAvg = +(distance / 1000 / averageSpeed).toFixed(1);
      //   let radarDayDataAvg = [
      //     distanceAvg,
      //     averageSpeedAvg,
      //     maxSpeedAvg,
      //     hourAvg
      //   ];
      //   this.cdata.radarDataAvg.unshift(radarDayDataAvg);
      // }

    },
    arrayContains(array, item){
      for(var i = 0; i < array.length; i++){
        if(array[i] == item){
          return true;
        }
      }
      return false;
    },
    formatData (that, list) {
      var datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[], statusEc:[], waterTemperature:[], waterlevel:[], ph:[], maxDataArr:[]};
      // var maxDataArr = {innerTemperature:0, innerHumidity:0, innerBright:0, statusEc:0, waterTemperature:0, waterlevel:0, ph:[]};
      var maxDataArr = [0, 0, 0, 0, 0, 0, 0];

      for(var i = 0; i < list.length; i++){
        var sensorData = list[i];
        if(sensorData.createTime == null) continue;
        
        var time = sensorData.createTime.substring(11, 13);
        
        if(that.arrayContains(datas.times, time)==false){
          datas.times.push(time);
          for(var property in sensorData){
            var tmpData = 0;
            if(property=="innerTemp"){
              tmpData = Math.round(sensorData.innerTemp * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[0]) {
                maxDataArr[0] = tmpData;
              }
              datas.innerTemperature.push(tmpData);
            }else if(property=="innerHumidity"){
              tmpData = Math.round(sensorData.innerHumidity * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[1]) {
                maxDataArr[1] = tmpData;
              }
              datas.innerHumidity.push(tmpData);
            }else if(property=="innerBright"){
              tmpData = Math.round(sensorData.innerBright * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[2]) {
                maxDataArr[2] = tmpData;
              }
              datas.innerBright.push(tmpData);
            }else if(property=="statusEC"){
              tmpData = Math.round(sensorData.statusEC * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > 100) tmpData = 100;
              if(tmpData > maxDataArr[3]) {
                maxDataArr[3] = tmpData;
              }
              datas.statusEc.push(tmpData);
            }else if(property=="waterTemp"){
              tmpData = Math.round(sensorData.waterTemp * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[4]) {
                maxDataArr[4] = tmpData;
              }
              datas.waterTemperature.push(tmpData);
            }else if(property=="waterLevel"){
              tmpData = Math.round(sensorData.waterLevel * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > 100) tmpData = 100;
              if(tmpData > maxDataArr[5]) {
                maxDataArr[5] = tmpData;
              }
              datas.waterlevel.push(tmpData);
            }else if(property=="statusPH"){
              tmpData = Math.round(sensorData.statusPH * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[6]) {
                maxDataArr[6] = tmpData;
              }
              datas.ph.push(tmpData);
            }
            
          }
          
        }
        
      }
      
      for(var j = 0; j < maxDataArr.length; j++) {
        
        if(Math.floor(maxDataArr[j] / 100) > 0) {
          maxDataArr[j] = (Math.floor(maxDataArr[j] / 100) + 1) * 100;
        } else {
          maxDataArr[j] = 100;
        }
        // else if(Math.floor(maxDataArr[j] / 10) > 0) {
        //   maxDataArr[j] = (Math.floor(maxDataArr[j] / 10) + 1) * 10;
        // } else {
        //   maxDataArr[j] = 10;
        // }
      }
      datas.maxDataArr = maxDataArr.slice(3, 6);  //maxDataArr.slice(0, 3);  //
      // that.cdata.maxDataArr = maxDataArr.slice(3, 6);  //maxDataArr.slice(0, 3);  //
      // console.log(JSON.stringify(that.cdata.maxDataArr));

      return datas;
    },
    
    //存储传感器数据
    getSensorHistoryDataSave(groupId, datas) {
      if(datas == null) {
        datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[], statusEc:[], waterTemperature:[], waterlevel:[], ph:[], maxDataArr:[]};
      }
      moduleDataArr[groupId - 1] = datas;
    },
    //获取数据，渲染页面
    getSensorHistoryDataFun(that, datas) {
        that.cdata.maxDataArr = datas.maxDataArr;

        that.cdata.timeCategory = datas.times;
        that.cdata.sensorData[0] = datas.statusEc; //datas.innerTemperature;
        that.cdata.sensorData[1] = datas.waterTemperature; //datas.innerHumidity;
        that.cdata.sensorData[2] = datas.waterlevel; //datas.innerBright;
        
        that.cdata.sensorLineData = that.cdata.sensorData[0];
        that.cdata.maxData = that.cdata.maxDataArr[0];
        that.cdata.sensorYText = that.cdata.sensorYArr[0];
    },
    //获取传感器数据
    getSensorHistoryData (that, groupId, resData) {
      // console.log(resData);
      // var globalData = this.$store.state.globalData;
      // if(globalData.curMachine == '') return;
      
      // let that = this;
      
      // var curDate = new Date();
      // curDate.setHours(0);
      // curDate.setMinutes(0);
      // curDate.setSeconds(0);
      // var curDateDay = formatTime(curDate, 'HH:mm:ss')
      // var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
      // var currentDateStr = curDateYear + " " + curDateDay;
      // var machineId = globalData.curMachine;  //"872204150001"; //
      // // var groupId = 1;
      // // if(globalData.waterSensorNum > 1) {
      // //   groupId = globalData.curWaterSensorNo + 1;
      // // }
      
      // var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
      this.$httpApi.getMachineSensorHistory(resData, function(data) {
        // var data = d.message;
        // console.log(JSON.stringify(data)); 
        if(data == null || data.length == 0) {
          that.getSensorHistoryDataSave(groupId, null);
          return;
        }
        
        var datas = that.formatData(that, data);
        // console.log(JSON.stringify(datas));
        //存储传感器数据
        that.getSensorHistoryDataSave(groupId, datas);
        //渲染页面
        that.getSensorHistoryDataFun(that, datas);

        //更新雷达图
        that.setData();
      }, function(xhr) {
        console.log(xhr.responseText);
      });
    },
    //获取多组
    getSensorHistoryDataMulti () {
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;
      
      let that = this;
      
      var curDate = new Date();
      // curDate.setHours(0);
      // curDate.setMinutes(0);
      // curDate.setSeconds(0);
      var curDateDay = formatTime(curDate, 'HH:mm:ss')
      var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
      var currentDateStr = curDateYear + " " + curDateDay;
      var machineId = globalData.curMachine;  //"872204150001"; //
      // var groupId = 1;
      // if(globalData.waterSensorNum > 1) {
      //   groupId = globalData.curWaterSensorNo + 1;
      // }
      // //1 主板数据 2~11 子系统数据
      // var groupId = 1;
      // if(globalData.waterSensorNum > 1) {
      //   for(var i = 1; i <= globalData.waterSensorNum; i++) {
      //     groupId = i + 1;
      //     var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
      //     that.getSensorHistoryData(that, groupId, resData);
      //   }
      // } else {
      //   var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
      //   that.getSensorHistoryData(that, groupId, resData);
      // }

      //工程模组
      //1 主板数据 2~11 子系统数据
      var groupId = 1;
      if(globalData.curStyleSize == 7 || globalData.curStyleSize == 8) {
        for(var i = 1; i <= globalData.waterSensorNum; i++) {
          var moduleIndex = globalData.waterSensorList[i-1];
          groupId = moduleIndex + 1;
          var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
          that.getSensorHistoryData(that, groupId, resData);
        }
      } else {
        var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
        that.getSensorHistoryData(that, groupId, resData);
      }
      
    },

    //----家用机----
    formatData01 (that, list) {
      var datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[], statusEc:[], waterTemperature:[], waterlevel:[], ph:[]};
      // var maxDataArr = {innerTemperature:0, innerHumidity:0, innerBright:0, statusEc:0, waterTemperature:0, waterlevel:0, ph:[]};
      var maxDataArr = [0, 0, 0, 0, 0, 0, 0];

      for(var i = 0; i < list.length; i++){
        var sensorData = list[i];
        if(sensorData.collectTime == null) continue;
        
        var time = sensorData.collectTime.substring(11, 13);
        
        if(that.arrayContains(datas.times, time)==false){
          datas.times.push(time);
          for(var property in sensorData){
            var tmpData = 0;
            if(property=="statusAirTemperature"){
              tmpData = Math.round(sensorData.statusAirTemperature * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[0]) {
                maxDataArr[0] = tmpData;
              }
              datas.innerTemperature.push(tmpData);
            }else if(property=="statusAirHumidity"){
              tmpData = Math.round(sensorData.statusAirHumidity * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[1]) {
                maxDataArr[1] = tmpData;
              }
              datas.innerHumidity.push(tmpData);
            }else if(property=="statusIllumination"){
              tmpData = Math.round(sensorData.statusIllumination * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[2]) {
                maxDataArr[2] = tmpData;
              }
              datas.innerBright.push(tmpData);
            }else if(property=="statusNutrientIndex"){
              tmpData = Math.round(sensorData.statusNutrientIndex * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > 100) tmpData = 100;
              if(tmpData > maxDataArr[3]) {
                maxDataArr[3] = tmpData;
              }
              datas.statusEc.push(tmpData);
            }else if(property=="statusWaterTemperature"){
              tmpData = Math.round(sensorData.statusWaterTemperature * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > 100) tmpData = 100;
              if(tmpData > maxDataArr[4]) {
                maxDataArr[4] = tmpData;
              }
              datas.waterTemperature.push(tmpData);
            }else if(property=="statusWaterLevel"){
              tmpData = Math.round(sensorData.statusWaterLevel * Math.pow(10, 2)) / Math.pow(10, 2);
              tmpData = tmpData / 10;
              if(tmpData > 100) tmpData = 100;
              if(tmpData > maxDataArr[5]) {
                maxDataArr[5] = tmpData;
              }
              datas.waterlevel.push(tmpData);
            }else if(property=="statusPH"){
              tmpData = Math.round(sensorData.statusPH * Math.pow(10, 2)) / Math.pow(10, 2);
              if(tmpData > maxDataArr[6]) {
                maxDataArr[6] = tmpData;
              }
              datas.ph.push(tmpData);
            }
            
          }
          
        }
        
      }
      
      for(var j = 0; j < maxDataArr.length; j++) {
        
        if(Math.floor(maxDataArr[j] / 100) > 0) {
          maxDataArr[j] = (Math.floor(maxDataArr[j] / 100) + 1) * 100;
        } else {
          maxDataArr[j] = 100;
        }
        // else if(Math.floor(maxDataArr[j] / 10) > 0) {
        //   maxDataArr[j] = (Math.floor(maxDataArr[j] / 10) + 1) * 10;
        // } else {
        //   maxDataArr[j] = 10;
        // }
      }
      that.cdata.maxDataArr = maxDataArr.slice(3, 6);  //maxDataArr.slice(0, 3);  //
      // console.log(JSON.stringify(that.cdata.maxDataArr));

      return datas;
    },
    //获取传感器数据
    getSensorHistoryDataWithHour () {
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;
      
      let that = this;
      
      var curDate = new Date();
      // curDate.setHours(0);
      // curDate.setMinutes(0);
      // curDate.setSeconds(0);
      var curDateDay = formatTime(curDate, 'HH:mm:ss')
      var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
      var currentDateStr = curDateYear + " " + curDateDay;
      
      var machineId = globalData.curMachine; //"872204150001"; //
      var resData = "?machineCode=" + machineId + "&date=" + currentDateStr;
      this.$httpApi.getMachineSensorDataWithHour(resData, function(data) {
        // var data = d.message;
        // console.log(JSON.stringify(data)); 
        if(data == null || data.length == 0) return;
        
        var datas = that.formatData01(that, data);
        // console.log(JSON.stringify(datas));
        
        that.cdata.timeCategory = datas.times;

        that.cdata.sensorData[0] = datas.statusEc; //datas.innerTemperature;
        that.cdata.sensorData[1] = datas.waterTemperature; //datas.innerHumidity;
        that.cdata.sensorData[2] = datas.waterlevel; //datas.innerBright;
        
        that.cdata.sensorLineData = that.cdata.sensorData[0];
        that.cdata.maxData = that.cdata.maxDataArr[0];
        that.cdata.sensorYText = that.cdata.sensorYArr[0];

        //更新雷达图
        that.setData();
      }, function(xhr) {
        console.log(xhr.responseText);
      });
    }


  }
  
};
</script>

<style lang="scss" scoped>
</style>