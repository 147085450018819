<template>
    <div id="setting" ref="setting">
        <h1 @click="settingFunEvent('setList')">{{title}}</h1>
        <div class="content-box" :style="{display:setListDisplay}">
            <div class="content-box-layer">
                <dv-border-box-9>
                    <div class="content-item" @click="settingFunEvent('deviceOrder')">
                        <span>设备排序</span>
                    </div>
                </dv-border-box-9>
            </div>
            <div class="content-box-layer content-box-blank">
                <dv-border-box-9>
                    <div class="content-item" @click="settingFunEvent('changePwd')">
                        <span>修改密码</span>
                    </div>
                </dv-border-box-9>
            </div>
        </div>
        <div :style="{display:setContentListDisplay}">
            <deviceOrder v-if="deviceOrderShow"/>
        </div>
        <div :style="{display:setContentListDisplay}">
            <changePwd v-if="changePwdShow"/>
        </div>
       
    </div>
</template>
<script>

import deviceOrder from "./deviceOrder"
import changePwd from "./changePwd"

export default {
    data() {
        return {
            title: "设置",
            setListDisplay: "flex",
            setContentListDisplay: "none",
            deviceOrderShow: false,
            changePwdShow: false,
        }
    },
    components: {
        deviceOrder,
        changePwd,
    },
    mounted() {

    },
    beforeDestroy () {

    },
    methods: {
        settingFunEvent(item) {
            this.setListDisplay = "none";
            this.setContentListDisplay = "none";
            this.deviceOrderShow = false;
            this.changePwdShow = false;

            switch(item) {
                case 'setList':
                    this.title = "设置";
                    this.setListDisplay = "flex";
                    break;
                case 'deviceOrder': 
                    this.title = "设置 - 设备排序";
                    this.setContentListDisplay = "block";
                    this.deviceOrderShow = true;
                    break;
                case 'changePwd': 
                    this.title = "设置 - 修改密码";
                    this.setContentListDisplay = "block";
                    this.changePwdShow = true;
                    break;
                default: break;
            }
        }
    }
}

</script>
<style lang="scss" scoped>
@import '../assets/scss/setting.scss';
</style>
