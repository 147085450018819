<template>
  <div id="index" ref="appRef">
    <div class="bg">
      
      <!-- <dv-loading v-if="loading">Loading...</dv-loading> -->
      <dv-loading v-if="loading">
        <div style="text-align:center;">
          <h2><strong>Loading...</strong></h2>
          <h2 style="margin-top:10px;"><strong>{{percent}}</strong></h2>
        </div>
      </dv-loading>
      <div v-else class="host-body">
        <div style="display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin:0px 60px 5px 60px;">
          <div style="display:flex;flex-direction:row;align-items:center;">
            <img src="/ssy-logo.png">
            <div style="margin-left:80px;font-weight:bolder;font-size:20px;color: seagreen;">
              <span>用</span>
              <span style="margin-left:30px;">科</span>
              <span style="margin-left:30px;">技</span>
              <span style="margin-left:30px;">•</span>
              <span style="margin-left:30px;">种</span>
              <span style="margin-left:30px;">未</span>
              <span style="margin-left:30px;">来</span>
            </div>
          </div>
          
          <!-- <span style="font-weight:bolder;font-size:28px;color: seagreen;">河北普兰特生物科技有限公司</span> -->
          <span style="font-weight:bolder;font-size:28px;color: seagreen;">{{curCompanyName}}</span>
        </div>

        <!-- <div style="display:flex;flex-direction:row;"> -->
        <div class="navbar-before" :style="{display:navBarDisplay}">
          <dv-decoration-10 :color="['#66cc99']" style="width:50%;height:5px;" />
          <dv-decoration-10 :color="['#66cc99']" style="width:50%;height:5px;transform: rotateY(180deg);" />
        </div>

        <!-- <div style="display:flex;flex-direction:row;justify-content:space-around;margin:25px 0;font-weight:bolder;font-size:22px;color:cadetblue;"> -->
        <div class="navbar" :style="{display:navBarDisplay}">
          <span @click="myMavigate('dataScreen')">上蔬源可视化平台</span>
          <span @click="myMavigate('proImpManual')">项目实施手册</span>
          <span @click="myMavigate('courseOutline')">课程大纲</span>
          <span @click="myMavigate('schCoopManual')">学校合作手册</span>
          <span @click="myMavigate('merchManual')">招商手册</span> 
        </div>

        <div class="d-flex jc-center">
          <!-- <dv-decoration-10 class="dv-dec-10" /> -->
          <div class="dv-dec-10" style="background-color:#568aea;height:2px;"></div>
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title" @click="myMavigate('setting')">
              <!-- <span class="title-text">上蔬源可视化平台</span> -->
              <span class="title-text">{{navItemTitle}}</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <!-- <dv-decoration-10 class="dv-dec-10-s" /> -->
          <div class="dv-dec-10" style="background-color:#568aea;height:2px;"></div>
        </div>
        
        <div class="doc" :style="{display:proImpManualDisplay}">
          <!-- <navDocument /> -->
          <navDocument1 ref="navDocument1" v-if="proImpManualShow"/>
        </div>
        <div class="doc" :style="{display:courseOutlineDisplay}">
          <navDocument2 ref="navDocument2" v-if="courseOutlineShow"/>
        </div>
        <div class="doc" :style="{display:schCoopManualDisplay}">
          <navDocument3 ref="navDocument3" v-if="schCoopManualShow"/>
        </div>
        <div class="doc" :style="{display:merchManualDisplay}">
          <navDocument4 ref="navDocument4" v-if="merchManualShow"/>
        </div>
        <div :style="{display:dataScreenDisplay}">
          <!-- <navDataScreen ref="navDataScreen" v-if="dataScreenShow"/> -->
          <navDataScreen ref="navDataScreen"/>
        </div>
        <div :style="{dislay:settingDisplay}">
          <setting ref="setting" v-if="settingShow" />
        </div>
        
        <div :style="{display:gbControlDisplay}">
          <gbControl ref="gbControl" v-if="gbControlShow"/>
        </div>
        <div :style="{display:cameraControlDisplay}">
          <cameraControl ref="cameraControl" v-if="cameraControlShow"/>
        </div>
        
      </div>
    </div>
  </div>

  
</template>

<script>

import drawMixin from "../utils/drawMixin";

import Transfer from "@/utils/transfer.js";
import setting from "../setting/index"
import navDataScreen from './navDataScreen'
import navDocument1 from './navDocument1'
import navDocument2 from './navDocument2'
import navDocument3 from './navDocument3'
import navDocument4 from './navDocument4'

import gbControl from '../camera/gbControl'
import cameraControl from '../camera/cameraControl'


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      loading: true,
      percent: '0%',
      decorationColor: ['#568aea', '#000000'],
      curCompanyName: '河北普兰特生物科技有限公司',
      navItemTitle: '上蔬源可视化平台',
      navBarDisplay: "flex",  //目录
      settingDisplay:"none",  //设置
      dataScreenDisplay: "block", //上蔬源可视化平台
      proImpManualDisplay: "none", //项目实施手册
      courseOutlineDisplay: "none", //课程大纲
      schCoopManualDisplay: "none", //学校合作手册
      merchManualDisplay: "none", //招商手册
      gbControlDisplay: "none", //国标摄像头控制
      cameraControlDisplay: "none", //萤石摄像头控制

      settingShow: false,
      dataScreenShow: true,
      proImpManualShow: false,
      courseOutlineShow: false,
      schCoopManualShow: false,
      merchManualShow: false,
      gbControlShow: true,
      cameraControlShow: true,
    }
  },
  components: {
    setting,
    navDataScreen,
    navDocument1,
    navDocument2,
    navDocument3,
    navDocument4,
    gbControl,
    cameraControl,
  },
  mounted() {
    // var globalData = this.$store.state.globalData;
    // // this.cancelLoading();
    // this.initView(globalData);
    // this.preload(globalData);
    this.autoLogin();

    //国标
    Transfer.$on('index-gbControl', (msg) => {
      //false: 关， true：开
      if(msg.switch) {
        this.dataScreenDisplay = "none";
        //this.dataScreenShow = false;
        this.gbControlDisplay = "block";
        //this.gbControlShow = true;
        
        Transfer.$emit('gbControl', msg);
      } else {
        this.dataScreenDisplay = "block";
        //this.dataScreenShow = true;
        this.gbControlDisplay = "none";
        //this.gbControlShow = false;

        //可视化平台  重新播放视频
        Transfer.$emit('navDataScreen-centerRight2', msg);
      }
      
    });
    //萤石
    Transfer.$on('index-cameraControl', (msg) => {
      //false: 关， true：开
      if(msg.switch) {
        this.dataScreenDisplay = "none";
        //this.dataScreenShow = false;
        this.cameraControlDisplay = "block";
        //this.cameraControlDisplay = true;
        
        Transfer.$emit('cameraControl', msg);
      } else {
        this.dataScreenDisplay = "block";
        //this.dataScreenShow = true;
        this.cameraControlDisplay = "none";
        //this.cameraControlDisplay = false;

        //可视化平台  重新播放视频
        Transfer.$emit('navDataScreen-centerRight1', msg);
      }
      
    });
    
  },
  beforeDestroy () {
    var globalData = {
      companyName: '',
      companyNameShow: false,
      curGroupName: null,
      curGroupId: null,
      curMachine: '',
      curOnline: 0,
      curUniqueWebcamSerial: '',
      curWebcamSerial: '',
      curStyleSize: null,
      curProtocolType: null,
      accessToken: null,
      loginToken: null,
      pumpNum: 1,
      curPumpNo: 1,
      pumpList: null,
      curPumpStatus: 0,
      envSensorNum: 1,
      curEnvSensorNo: 1,
      envSensorList: null,
      waterSensorNum: 1,
      curWaterSensorNo: 1,
      waterSensorList: null,
      navBarShow: 1,
      quitBtnValid: 1,
      webcamShow: 1,
      webcamType: 0,
      machineLoop: true,
      province: '',
      region: '',
      longitude: null,
      latitude: null,
      regionList: null
    }
    this.$store.commit("setGlobalData", globalData);
  },
  methods: {
    autoLogin() {
      var globalData = this.$store.state.globalData;
      var curUserName = this.$store.state.curUserName;
      if(curUserName != undefined && curUserName != null && curUserName != '') {
        var that = this;
        var userName = curUserName;
        this.$store.commit("setCurUserName", '');

        var resData = "?userName=" + userName + "&password=&noPwdLogin=1";
        this.$httpApi.login(resData, function(d) {
          // console.log(JSON.stringify(d));
          if(d.result == true) {
            
            globalData = {
              companyName: d.data.enterprise,
              companyNameShow: d.data.enterpriseShow,
              curGroupName: d.data.groupName,
              curGroupId: d.data.groupId,
              curMachine: '',
              curUniqueWebcamSerial: '',
              curWebcamSerial: '',
              curStyleSize: null,
              curProtocolType: null,
              accessToken: null,
              loginToken: d.data.token,
              pumpNum: 1,
              curPumpNo: 1,
              curPumpStatus: 0,
              pumpList: null,
              envSensorNum: 1,
              curEnvSensorNo: 1,
              envSensorList: null,
              waterSensorNum: 1,
              curWaterSensorNo: 1,
              waterSensorList: null,
              navBarShow: d.data.navBarExists,
              quitBtnValid: d.data.quitBtnExists,
              webcamShow: d.data.webcamExists,
              webcamType: d.data.webcamType,
              machineLoop: true,
              province: d.data.province,
              region: d.data.region,
              longitude: d.data.longitude,
              latitude: d.data.latitude,
					    regionList: d.data.regionList,
            }
            
            that.$store.commit("setGlobalData", globalData);
            that.$store.commit("setCurUserName", d.data.userName);
            
            that.initView(that, globalData);
            that.preload(globalData);
          } else {
            that.$Toast({
              content: d.message,
              type: 'error',
              // hasClose: true
            })
          }
          
        }, function(xhr) {
          console.log(xhr.responseText);
        });
      } else {
        // this.cancelLoading();
        this.initView(this, globalData);
        this.preload(globalData);
      }
      
    },
    myMavigate(nav) {
      if(nav == 'setting') {
        if(!(this.dataScreenShow == true || this.settingShow == true)) {
          return;
        }
      }
      
      var preSettingShow = this.settingShow;

      this.settingDisplay = "none";
      this.dataScreenDisplay = "none";
      this.proImpManualDisplay = "none"
      this.courseOutlineDisplay = "none"
      this.schCoopManualDisplay = "none"
      this.merchManualDisplay = "none"
      this.settingShow = false;
      this.dataScreenShow = false;
      this.proImpManualShow = false;
      this.courseOutlineShow = false;
      this.schCoopManualShow = false;
      this.merchManualShow = false;
      switch(nav) {
        case 'setting':
          //设置页面
          this.navItemTitle = "上蔬源可视化平台";
          if(preSettingShow == true) {
            this.settingDisplay = "none";
            this.settingShow = false;
            
            this.dataScreenDisplay = "block";
            this.dataScreenShow = true;
          } else {
            this.settingDisplay = "block";
            this.settingShow = true;
          }
          
          break;
        case 'dataScreen':
          //上蔬源可视化平台
          this.navItemTitle = "上蔬源可视化平台";
          this.dataScreenDisplay = "block";
          this.dataScreenShow = true;
          break;
        case 'proImpManual':
          //项目实施手册
          this.navItemTitle = "项目实施手册";
          this.proImpManualDisplay = "block";
          this.proImpManualShow = true;
          break;
        case 'courseOutline':
          //课程大纲
          this.navItemTitle = "课程大纲";
          this.courseOutlineDisplay = "block"
          this.courseOutlineShow = true;
          break;
        case 'schCoopManual':
          //学校合作手册
          this.navItemTitle = "学校合作手册";
          this.schCoopManualDisplay = "block"
          this.schCoopManualShow = true;
          break;
        case 'merchManual':
          //招商手册
          this.navItemTitle = "招商手册";
          this.merchManualDisplay = "block"
          this.merchManualShow = true;
          break;
        default:break;
      }
    },
    initView(that, globalData) {
      
      if(globalData.navBarShow == 1) {
        that.navBarDisplay = "flex";
      } else {
        that.navBarDisplay = "none";
      }
      
      if(globalData.companyNameShow == true) {
        if(globalData.companyName != null && globalData.companyName != '') {
          that.curCompanyName = globalData.companyName;
        }
      }
      
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    preload(globalData) {
      
      if(globalData.navBarShow == 0) {
        this.loading = false;
        return;
      }

      var imgs = [
        "img/proImpManual/1.png",
        "img/proImpManual/2.png",
        "img/proImpManual/3.png",
        "img/proImpManual/4.png",
        "img/proImpManual/5.png",
        "img/proImpManual/6.png",
        "img/proImpManual/7.png",
        "img/proImpManual/8.png",
        "img/proImpManual/9.png",
        "img/proImpManual/10.png",
        "img/proImpManual/11.png",
        "img/proImpManual/12.png",
        "img/proImpManual/13.png",
        "img/proImpManual/14.png",
        "img/proImpManual/15.png",
        "img/proImpManual/16.png",
        "img/proImpManual/17.png",
        "img/proImpManual/18.png",
        "img/proImpManual/19.png",
        "img/proImpManual/20.png",
        "img/proImpManual/21.png",
        "img/proImpManual/22.png",
        "img/proImpManual/23.png",
        "img/proImpManual/24.png",
        "img/proImpManual/25.png",
        "img/proImpManual/26.png",
        "img/proImpManual/27.png",
        "img/proImpManual/28.png",
        "img/proImpManual/29.png",
        "img/proImpManual/30.png",
        "img/proImpManual/31.png",
        "img/proImpManual/32.png",
        "img/proImpManual/33.png",
        "img/proImpManual/34.png",
        "img/proImpManual/35.png",
        "img/proImpManual/36.png",
        "img/proImpManual/37.png",
        "img/proImpManual/38.png",
        "img/proImpManual/39.png",
        "img/proImpManual/40.png",
        "img/proImpManual/41.png",
        "img/courseOutline/1.png",
        "img/courseOutline/2.png",
        "img/courseOutline/3.png",
        "img/courseOutline/4.png",
        "img/courseOutline/5.png",
        "img/schCoopManual/1.png",
        "img/schCoopManual/2.png",
        "img/schCoopManual/3.png",
        "img/schCoopManual/4.png",
        "img/schCoopManual/5.png",
        "img/schCoopManual/6.png",
        "img/schCoopManual/7.png",
        "img/schCoopManual/8.png",
        "img/schCoopManual/9.png",
        "img/schCoopManual/10.png",
        "img/schCoopManual/11.png",
        "img/schCoopManual/12.png",
        "img/schCoopManual/13.png",
        "img/schCoopManual/14.png",
        "img/schCoopManual/15.png",
        "img/schCoopManual/16.png",
        "img/schCoopManual/17.png",
        "img/schCoopManual/18.png",
        "img/schCoopManual/19.png",
        "img/schCoopManual/20.png",
        "img/schCoopManual/21.png",
        "img/schCoopManual/22.png",
        "img/schCoopManual/23.png",
        "img/schCoopManual/24.png",
        "img/schCoopManual/25.png",
        "img/merchManual/1.png",
        "img/merchManual/2.png",
        "img/merchManual/3.png",
        "img/merchManual/4.png",
        "img/merchManual/5.png",
        "img/merchManual/6.png",
        "img/merchManual/7.png",
        "img/merchManual/8.png",
        "img/merchManual/9.png",
        "img/merchManual/10.png",
        "img/merchManual/11.png",
        "img/merchManual/12.png",
        "img/merchManual/13.png",
        "img/merchManual/14.png",
        "img/merchManual/15.png",
        "img/merchManual/16.png",
        "img/merchManual/17.png",
        "img/merchManual/18.png",
        "img/merchManual/19.png",
        "img/merchManual/20.png",
        "img/merchManual/21.png",
        "img/merchManual/22.png",
        "img/merchManual/23.png",
        "img/merchManual/24.png"
      ];
      
      let imgsSize = imgs.length;
      let count = 0;
      for (let img of imgs) {
        var imgStore = localStorage.getItem(img);
        if(imgStore != null) {
          count = this.preloadPercentShow(count, imgsSize);
          continue;
        }
        
        let image = new Image()
        image.src = img;

        image.onload = () => {
          localStorage.setItem(img, image);
          count = this.preloadPercentShow(count, imgsSize);
          // count++
          // // 计算图片加载的百分数，绑定到percent变量
          // let percentNum = Math.floor(count / imgsSize * 100);
          // this.percent = percentNum + `%`;

          // if(count >= imgsSize) {
          //   this.loading = false;
          // }
          
        }
        
      }
      
    },
    preloadPercentShow(count, imgsSize) {
      count++
      // 计算图片加载的百分数，绑定到percent变量
      let percentNum = Math.floor(count / imgsSize * 100);
      this.percent = percentNum + `%`;

      if(count >= imgsSize) {
        this.loading = false;
      }
      return count;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

</style>
