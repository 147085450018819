<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      // cdata: {}
      cdata: {
       xData: ["智灵钻", "智立方", "智立方L", "单面屏风", "双面屏风", "智灵宝", "工程模组", "集装箱", "其它"],
        seriesData: [
          { value: 3903, name: "智灵钻" },
          { value: 5656, name: "智立方" },
          { value: 4653, name: "智立方L" },
          { value: 1803, name: "单面屏风" },
          { value: 1053, name: "双面屏风" },
          { value: 5646, name: "智灵宝" },
          { value: 60, name: "工程模组" },
          { value: 50, name: "集装箱" },
          { value: 8, name: "其它" },
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>