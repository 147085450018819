<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="270px"
      width="260px"
    ></Echart>
    <!-- <Echart
      :options="options"
      id="centreLeft1Chart"
      height="270px"
      width="330px"
    ></Echart> -->
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        // console.log(JSON.stringify(newData));
        this.options = {
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea",
            "#2378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: { 
            show:true,
            orient: "horizontal",
            icon: "circle",
            bottom: 0,
            data: newData.xData,
            // data: ["单面屏风", "智立方", "智灵宝", "智灵钻", "双面屏风", "工程模组", "集装箱", "其它","智立方L"],
            textStyle: {
              color: "#fff",
              fontSize: 14
            }
          },
          series: [
            {
              name: "设备款式统计",
              type: "pie",
              radius: [10, 50],
              roseType: "area",
              center: ["50%", "40%"],
              // //重点代码段
              // label : {
              //   normal : {
              //     formatter: '{b}: {d}%',
              //     textStyle : {
              //       fontWeight : 'normal',
              //       fontSize : 11
              //     }
              //   }
              // },
              data: newData.seriesData,
              // data: [
              //   { value: 5, name: "单面屏风" },
              //   { value: 15, name: "智立方" },
              //   { value: 25, name: "智灵宝" },
              //   { value: 20, name: "智灵钻" },
              //   { value: 5, name: "双面屏风" },
              //   { value: 10, name: "工程模组" },
              //   { value: 10, name: "集装箱" },
              //   { value: 10, name: "其它" },
              //   { value: 10, name: "智立方L" },
              // ],
              //标线的属性设置，以及显示的文字
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{c}台"
                  }
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            
            }
          ]
        }

        // this.options = { ...this.options }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // this.changeTiming()
    // this.getData();
  },
  methods: {
    changeTiming() {
      
      setInterval(() => {
        this.changeNumber()
      }, 3000)
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index
        item.number = { ...item.number }
      })
    },
    getData:function(){
      let that = this;

      var resData = {'adminId':''};
      this.$httpApi.getMachineNum(resData, function(data) {
        // console.log(JSON.stringify(data)); 

        that.options.series[0].data[0].value = (data.zhiLingZuan == null | data.zhiLingZuan == '' ? '0' : data.zhiLingZuan);
        that.options.series[0].data[1].value = (data.zhiLiFang == null | data.zhiLiFang == '' ? '0' : data.zhiLiFang);
        that.options.series[0].data[2].value = (data.zhiLiFangL == null | data.zhiLiFangL == '' ? '0' : data.zhiLiFangL);
        that.options.series[0].data[3].value = (data.danMianPingFeng == null | data.danMianPingFeng == '' ? '0' : data.danMianPingFeng);
        that.options.series[0].data[4].value = (data.shuangMianPingFeng == null | data.shuangMianPingFeng == '' ? '0' : data.shuangMianPingFeng);
        that.options.series[0].data[5].value = (data.zhiLingBao == null | data.zhiLingBao == '' ? '0' : data.zhiLingBao);
        that.options.series[0].data[6].value = (data.gongChengMoZu == null | data.gongChengMoZu == '' ? '0' : data.gongChengMoZu);
        that.options.series[0].data[7].value = (data.jiZhuangXiang == null | data.jiZhuangXiang == '' ? '0' : data.jiZhuangXiang);
        that.options.series[0].data[8].value = (data.other == null | data.other == '' ? '0' : data.other);

        // console.log(JSON.stringify(that.options.series[0].data));
        that.options.series[0].data = { ...that.options.series[0].data };
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });
      
    }
  }

};
</script>

<style lang="scss" scoped>
</style>