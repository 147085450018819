<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-area" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <!-- <span class="fs-xl text mx-2">液体传感器</span> -->
          <span v-if="waterSensorNum > 1" class="fs-xl text mx-2">液体传感器: {{waterSensorNum}} - {{curWaterSensorNo}}</span>
          <span v-else class="fs-xl text mx-2">液体传感器</span>

          <div class="decoration2">
            <dv-decoration-2 :reverse="true" style="width:5px;height:6rem;" />
          </div>
        </div>
      </div>
      <div>
        <BottomRightChart />
      </div>
    </div>
  </div>
</template>

<script>
import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
import Transfer from "../utils/transfer.js";

export default {
  data() {
    return {
      timing: null,
      waterSensorNum: 1,
      curWaterSensorNo: 1
    }
  },
  components: {
    BottomRightChart
  },
  mounted(){
    var globalData = this.$store.state.globalData;
    // this.waterSensorNum = globalData.waterSensorNum;
    // this.curWaterSensorNo = globalData.curWaterSensorNo;
    // if(globalData.curProtocolType == 2 && this.waterSensorNum > 1) {
    //   this.changeTiming();
    // }
    
    Transfer.$on('bottomRight', (msg) => {
      this.waterSensorNum = globalData.waterSensorNum;
      this.curWaterSensorNo = globalData.curWaterSensorNo;
      
      if(globalData.curProtocolType == 2 && this.waterSensorNum > 1) {
        //停止定时器
        clearInterval(this.timing);
        this.timing = null;
        //重新启动定时器
        this.changeTiming();
      }

      // console.log(msg);
      Transfer.$emit('bottomRight-bottomRight','msg-bottomRight-bottomRight');
    });
  },
  beforeDestroy () {
    clearInterval(this.timing);
    this.timing = null;
  },
  methods: {
    changeTiming() {
      var globalData = this.$store.state.globalData;
      this.timing = setInterval(() => {
        if(globalData.curProtocolType == 2 && this.waterSensorNum > 1) {
          if(this.curWaterSensorNo >= this.waterSensorNum) {
            this.curWaterSensorNo = 1;
          } else {
            this.curWaterSensorNo += 1;
          }

          globalData.curWaterSensorNo = this.curWaterSensorNo;
          Transfer.$emit('bottomRight-bottomRight-child','msg-bottomRight-bottomRight-child');
          Transfer.$emit('bottomRight-index-center','msg-ottomRight-index-center');
        }
        
      }, this.$GLOBAL.deviceChildShowTiming);
      
    }
  }
};
</script>

<style lang="scss" class>
$box-height: 520px;
$box-width: 100%;
#bottomRight {
  padding: 14px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>