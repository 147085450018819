<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" style="margin:0 auto;"/>
</template>

<script>
import tdTheme from './theme.json' // 引入默认主题
// import '../map/fujian.js'
// import '../map/hebei.js'

//读取api路径下的js接口文件
const apiFiles = require.context('/node_modules/echarts/map/js/province', true, /\.js$/)
const api = apiFiles.keys().reduce((api, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = apiFiles(modulePath)
  api[moduleName] = value.default
  return api
}, {})
//Vue.prototype.api = api;


export default {
  name: 'echart',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '2.5rem'
    },
    options: {
      type: Object,
      default: ()=>({})
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    options: {
      handler (options) {
        // 设置true清空echart缓存
        this.chart.setOption(options, true)
      },
      deep: true
    }
  },
  mounted () {
    this.$echarts.registerTheme('tdTheme', tdTheme); // 覆盖默认主题
    this.initChart();
  },
  beforeDestroy () {
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      // 初始化echart
      this.chart = this.$echarts.init(this.$el, 'tdTheme')
      this.chart.setOption(this.options, true)
    }
  }
}
</script>

<style>
</style>
