
<template>
    <div id="changePwd">
        <div class="content-item">
            <span class="label-blank">原密码：</span>
            <input type="text" :value="oldPwd" @change="pwdEvent($event, 'oldPwd')"/>
        </div>
        <div class="content-item content-item-blank">
            <span class="label-blank">新密码：</span>
            <input type="text"  placeholder="长度不大于20" :value="newPwd" @change="pwdEvent($event, 'newPwd')"/>
        </div>
        <div class="confirmBtn" @click="confirmEvent()">确&nbsp;&nbsp;定</div>

        <div class="fun-tip" :style="{display:tipdisplay}">
            <span style="color:#d3d6dd;">{{tipText}}</span>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            oldPwd:'',
            newPwd:'',
            tipdisplay: "none",
            tipText: '',
        }
    },
    components: {

    },
    mounted() {

    },
    beforeDestroy () {

    },
    methods: {
        //序号编号
        pwdEvent(e, flag) {
            var curVal = e.target.value;
            if(flag == "oldPwd") {
                this.oldPwd = curVal;
                return;
            } 

            if(curVal.length > 20) {
                this.tipShowFun(this, "密码长度太长");
                return;
            }
            this.newPwd = curVal;
        },
        confirmEvent() {
            var curUserName = this.$store.state.curUserName;
            let that = this;

            var resData = "?userName=" + curUserName + "&oldPwd=" + this.oldPwd + "&newPwd=" + this.newPwd;
            this.$httpApi.changePwd(resData, function(data) {
                // console.log(JSON.stringify(data)); 
                if(data.result == true) {
                    that.tipShowFun(that, "修改成功");
                } else {
                    that.tipShowFun(that, "修改失败");
                }
            }, function(xhr) {
                console.log(xhr.responseText);
                that.tipShowFun(that, "修改异常");
            });


        },
        //消息提示
        tipShowFun(that, txt) {
            that.tipText = txt;
            that.tipdisplay = "block";
            setTimeout(() => {
                that.tipdisplay = "none";
                that.tipText = '';
            }, 1000);
        },
    }
}
</script>

<style lang="scss" scoped>

#changePwd {
    width:350px;
    margin:10px auto;
    margin-top:10px;
    color: #d3d6dd;
    font-size: 18px;
    
    text-align: center;
    display:flex;
    flex-direction:column;
    align-items:center;

    .content-item {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
    }

    .content-item-blank {
        margin-top:20px;
    }

    .label-blank {
        margin-right:5px;
    }

    .confirmBtn {
        width:100%;
        margin-top:30px;
        padding:15px 20px;
        border:1px solid #257dff;
    }

    input {
        width:250px;
        height:40px;
        padding-left:10px;
        padding-right:10px;
        border: 1px solid #257dff;
        background-color:rgba(0,0,0,0);
        font-size: 18px;
        color:rgb(0, 186, 255);
    }

    .fun-tip {
        // position:absolute;
        // left:40%;
        // bottom:20px;
        width:200px;
        margin-top:40px;
        padding:12px 20px;
        background-color: rgba(19, 25, 47, 0.6);
        border:2px solid rgb(46, 96, 153);
        border-radius:10px;
    }
}

</style>
