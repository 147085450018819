<template>
    <div id="gbControl" ref="gbControl">
        <div class="content-box">
            <div id="container" :width="{width}" :height="{height}" style="background-color: #000000;">
                <canvas id="video-container"></canvas>
            </div>

            <div class="control-fun">
                <div class="display-flex-column" :style="{display:controlDisplay}">
                    <div class="ptz-title">云 台</div>
                    <div class="display-flex-column">
                        <span id="ptzUp" class="icon iconfont icon-fangxiangsanjiao-xiangshang" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                        <div class="display-flex-row" style="margin-top:40px; margin-bottom:50px;">
                            <span id="ptzLeft" class="icon iconfont icon-fangxiangsanjiao-xiangzuo iconfont-left-right" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                            <span id="ptzRight" class="icon iconfont icon-fangxiangsanjiao-xiangyou iconfont-left-right" style="margin-left:80px;" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                        </div>
                        <span id="ptzDown" class="icon iconfont icon-fangxiangsanjiao-xiangxia" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                    </div>
                    <div class="display-flex-row" style="margin-top:60px;">
                        <span id="ptzIn" class="icon iconfont icon-zengjia" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                        <span id="ptzOut" class="icon iconfont icon-jianshao" style="margin-left:60px;" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                    </div>
                </div>
                
                <div class="return-btn" @click="returnBtnEvent">返回</div>

            </div>
            


            <!-- <div class="display-flex-column control-fun" >
                <div class="display-flex-column" :style="{display:controlDisplay}">
                    <span id="ptzUp" class="icon iconfont icon-fangxiangsanjiao-xiangshang" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                    <div class="display-flex-row" style="margin-top:40px; margin-bottom:50px;">
                        <span id="ptzLeft" class="icon iconfont icon-fangxiangsanjiao-xiangzuo iconfont-left-right" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                        <span id="ptzRight" class="icon iconfont icon-fangxiangsanjiao-xiangyou iconfont-left-right" style="margin-left:80px;" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                    </div>
                    <span id="ptzDown" class="icon iconfont icon-fangxiangsanjiao-xiangxia" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                </div>
                <div class="display-flex-row" style="margin-top:60px;" :style="{display:controlDisplay}">
                    <span id="ptzIn" class="icon iconfont icon-zengjia" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                    <span id="ptzOut" class="icon iconfont icon-jianshao" style="margin-left:60px;" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                </div>

                
                <div class="return-btn" @click="returnBtnEvent">返回</div>
                
            </div> -->
            
        </div>
        
    </div>
</template>
<script>

import Transfer from "@/utils/transfer.js";
import WebglScreen from "@/utils/WebglScreen.js";
import '@/assets/iconfont/iconfont.css';

export default {
    data() {
        return {
            controlDisplay: 'flex',
            width:1280,
            height:720,
            //摄像头列表  摄像头数组索引
            srcIndex: null,
            //播放摄像头 解码索引
            index: null,
            // deviceSerial: '13018500401320000002',
            // playSsrc:'0130000002',
            deviceSerial: '',
            playSsrc:'',
            worker: null,
            webglRender: null,
        }
    },
    components: {
        
    },
    mounted() {
        // //采取异步async
        // (async () => {
        //     this.loadFFmpegFun();
        // })();
        
        Transfer.$on('gbControl', (msg) => {
            this.deviceSerial = msg.deviceSerial;
            this.playSsrc = msg.playSsrc;
            this.srcIndex = msg.srcIndex;
            this.index = msg.index;
            if(msg.ptz == 0) {
                this.controlDisplay = 'none';
            } else {
                this.controlDisplay = 'flex';
            }

            //采取异步async
            (async () => {
                this.loadFFmpegFun();
            })();
        });
    },
    beforeDestroy () {
        this.destroyEvent();
        
    },
    methods: {
        destroyEvent() {
            if(this.deviceSerial != null) {
                ////发送结束播放指令
                //this.sendWebcamCmd(this.deviceSerial, "bye");
            }
            if(this.worker != null) {
                this.worker.postMessage('close');
            }
            if(this.webglRender != null) {
                this.webglRender.destroy();
            } 
            this.worker = null;
            this.webglRender = null;
            this.srcIndex = null;
            this.index = null;
        },
        async loadFFmpegFun() {
            this.startFFmpegWorkerOne(this);
        },
        startFFmpegWorkerOne(that) {
            var videoEleId = "video-container";
            var sipMediaServerIp = that.$GLOBAL.sipMediaServerIp;
            var sipMediaServerPort = that.$GLOBAL.sipMediaServerPort;

            if(that.deviceSerial == null) {
                return;
            }
            
            ////发送播放指令
            //that.sendWebcamCmd(this.deviceSerial, "play");
            
            //创建canvas渲染
            that.webglRender = that.getWebglScreen(that, videoEleId);

            var curWorker = new Worker('/js/worker.js', {name: videoEleId});
            that.worker = curWorker;
            
            curWorker.onmessage = function (event) {
                if(typeof event.data === "string") {
                    console.log('Received message ' + event.data);
                    return;
                }
                
                //视频流渲染canvas画面
                if(that.webglRender != null) {
                    that.webglRender.renderImg(that.width, that.height, event.data);   
                }
            }
            curWorker.onerror = function (event) {
                //console.log('ERROR: Line ' + event.lineno + ' in ' + event.filename + ': ' + event.message);
            }
            
            //worker.postMessage('Hello World');
            curWorker.postMessage({
                toPlay: true,
                ind: that.index,
                sipMediaServerIp: sipMediaServerIp,
                sipMediaServerPort: sipMediaServerPort,
                playSsrc: that.playSsrc,
                width: that.width,
                height: that.height,
            });
                
        },
        //创建canvas渲染
        getWebglScreen(that, videoEleId) {
            var video = document.getElementById(videoEleId);

            var initialCanvas = (canvas, width, height) => {
                canvas.width = width;
                canvas.height = height;
                return new WebglScreen(canvas);
            };
            var renderer = initialCanvas(video,that.width,that.height);
            return renderer;
        },
        //发送国标摄像头命令
        sendWebcamCmd(webcamSerial, type) {
            var msg = "";
            if(type == "play") {
                msg = "播放";
            } else if(type == "bye") {
                msg = "结束播放";
            }
            var param = "";
            var resData = "?deviceSerial=" + webcamSerial + "&type=" + type + "&param=" + param;
            this.$httpApi.sendWebcamCmd(resData, function(d) {
                if(d.message == true) {
                            console.log("正在" + msg);
                        } else {
                            console.log(msg + "失败");
                        }
            }, function(xhr) {
                console.log(xhr.responseText);
                console.log(msg + "异常");
            });
        },
        //方向控制 开始
        ptzStartEvent(e) {
            var deviceSerial = this.deviceSerial;
            var id = e.target.id;
            document.getElementById(id).classList.add("ptz-selected");
            
            var param = id.substring("ptz".length).toLowerCase();
            var type = "ptz";
            var resData = "?deviceSerial=" + deviceSerial + "&type=" + type + "&param=" + param;
            this.$httpApi.sendWebcamCmd(resData, function(d) {
                if(d.message == true) {
                    console.log("开始方向控制");
                } else {
                    console.log("开始方向控制失败");
                }
            }, function(xhr) {
                console.log(xhr.responseText);
                console.log("开始方向控制异常");
            });
            
        },
        //方向控制 开始
        ptzEndEvent(e) {
            var deviceSerial = this.deviceSerial;
            var id = e.target.id;
            document.getElementById(id).classList.remove("ptz-selected");
            
            var param = "stop";
            var type = "ptz";
            var resData = "?deviceSerial=" + deviceSerial + "&type=" + type + "&param=" + param;
            this.$httpApi.sendWebcamCmd(resData, function(d) {
                if(d.message == true) {
                    console.log("结束方向控制");
                } else {
                    console.log("结束方向控制失败");
                }
            }, function(xhr) {
                console.log(xhr.responseText);
                console.log("结束方向控制异常");
            });
            
        },
        //返回按钮
        returnBtnEvent() {
            var sendData = {};
            sendData.switch = false;
            sendData.srcIndex = this.srcIndex;
            //结束播放事件
            this.destroyEvent();
            //隐藏窗口
            Transfer.$emit('index-gbControl', sendData);
        },
    }
}

</script>
<style lang="scss" scoped>
@import '../assets/scss/camera.scss';
</style>
