
<template>
  <div id="deviceFunFan">
    <div class="bg-color-black">
      
      <div style="padding:10px;display:flex;flex-direction:row;align-items: center;">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <span style="margin-left:5px;margin-right:10px;">风扇控制</span>
        <dv-decoration-3 class="dv-dec-1" />
      </div>

      <el-carousel :autoplay="false" :arrow="elCarouseArrow" :indicator-position="elCarouseIndPos" :class="elCarouselMy">
          <el-carousel-item v-for="(itemData, ind) in curData" :key="ind">
            <div style="width:100%;text-align: center;" v-if="elCarouseItemNameShow">模组{{itemData.id}}</div>

            <ul>
              <li v-for="(item, index) in itemData.data" :key="index">
                <div class="list-item display-row">
                  <span>第{{item.id}}层</span>
                  <div class="display-row">
                    <div class="display-row">
                      <div class="display-row">
                        <span style="width:90px;margin-right:5px;">转速 : {{item.value}}%</span>
                        <span @mousedown="valueSubLoopEvent(ind, index)" @mouseup="valueSubLoopEndEvent(ind, index)" class="list-item-btn" onselectstart="return false">-</span>
                        <el-slider v-model="item.value" :show-tooltip="false" @change="valueChangeEvent(ind, index)" class="list-item-slider"></el-slider>
                        <span @mousedown="valueAddLoopEvent(ind, index)" @mouseup="valueAddLoopEndEvent(ind, index)" class="list-item-btn" onselectstart="return false">+</span>
                      </div>
                    </div>
                    <div class="display-row" style="margin-left:30px;">
                      <span style="margin-right:10px;">开关 : </span>
                      <el-switch
                        v-model="item.switch"
                        active-color="#257dff"
                        inactive-color="#666"
                        @change="switchChangeEvent(ind, index)">
                      </el-switch>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
        
          </el-carousel-item>
        </el-carousel>
      
      <div class="fun-tip" :style="{display:tipdisplay}">
          <span style="color:#d3d6dd;">{{tipText}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  
  
import Transfer from "../utils/transfer.js";

export default {
data() {
  return {
    //定时器
    numInternal:null,
    numInternalCount: 0, //定时计数器
    valueSubLoopTimer: null,
    valueAddLoopTimer: null,
    //设备参数
    curMachine: '',
    curOnline: 0, //0:离线，1：在线
    curStyleSize: null,  //1：智灵钻， 2：智立方， 3：智立方L， 4：单面屏风， 5：双面屏风， 6：智灵宝， 7：工程模组， 8：集装箱
    curProtocolType: null, //1：家用机协议，2：工程模组协议
    curData: [
      {
        id: 1,
        data:
        [
          {id: 1, switch: false, value: 100},
          {id: 2, switch: false, value: 100},
          {id: 3, switch: false, value: 100},
          {id: 4, switch: false, value: 100},
          {id: 5, switch: false, value: 100},
          {id: 6, switch: false, value: 100},
          {id: 7, switch: false, value: 100},
          {id: 8, switch: false, value: 100},
          {id: 9, switch: false, value: 100},
          {id: 10, switch: false, value: 100},
        ]
      }
    ],
    curDataRefreshFlag: false,  //设备数据刷新标志，false：未刷新  true：已刷新， 用于防止数量更新异步操作
    tipdisplay: "none",
    tipText: '',

    //横向滚动
    elCarouseArrow: 'never', //always: 一直显示切换箭头， nerver:从不显示切换箭头
    elCarouseIndPos: 'none', //'': 默认指示器的位置， none:不显示指示器
    elCarouselMy: 'el-carousel-my-no', //'el-carousel-my',
    elCarouseItemNameShow: false  //是否显示工程模组名称
  }
},
components: {

},
mounted() {
  Transfer.$on('deviceFunLightStyle', (msg) => {
    Transfer.$off('deviceFunLightStyle');

    var layerNum = 0;
    this.curMachine = msg.curMachine;
    this.curOnline = msg.curOnline;
    this.curStyleSize = msg.curStyleSize;
    this.curProtocolType = msg.curProtocolType;
    if((this.curProtocolType == 1) || 
        (this.curProtocolType == 2 && this.curStyleSize != 7 && this.curStyleSize != 8)) {
      
        if(this.curStyleSize == 1 || this.curStyleSize == 2 || this.curStyleSize == 3) {
          layerNum = 4;
        } else if(this.curStyleSize == 4 || this.curStyleSize == 5) {
          layerNum = 6;
        } else if(this.curStyleSize == 6) {
          layerNum = 1;
        }
    }

    if(layerNum > 0) {
      //删除多余层数据
      if(this.curData[0].data.length > layerNum) {
          this.curData[0].data.splice(layerNum);
        }
    }
    
  });
  Transfer.$on('deviceFunFan', (msg) => {
    Transfer.$off('deviceFunFan');
    // console.log(JSON.stringify(msg));
    this.curMachine = msg.curMachine;
    this.curOnline = msg.curOnline;
    this.curStyleSize = msg.curStyleSize;
    this.curProtocolType = msg.curProtocolType;
    //this.curData = msg.curData;

    var layerNum = 0;
    var curDataArr = [];
      if(this.curProtocolType == 2) {
        if(this.curStyleSize == 7 || this.curStyleSize == 8) {
          this.elCarouseArrow = "always";
          this.elCarouseIndPos = "";
          this.elCarouselMy = "el-carousel-my";
          this.elCarouseItemNameShow = true;

          curDataArr = msg.curData;
        } else {
          curDataArr[0] = {};
          curDataArr[0] = msg.curData[0];

          if(this.curStyleSize == 1 || this.curStyleSize == 2 || this.curStyleSize == 3) {
            layerNum = 4;
          } else if(this.curStyleSize == 4 || this.curStyleSize == 5) {
            layerNum = 6;
          } else if(this.curStyleSize == 6) {
            layerNum = 1;
          }
        }
        
      } else {
        curDataArr[0] = {};
        curDataArr[0].data = msg.curData;

        if(this.curStyleSize == 1 || this.curStyleSize == 2 || this.curStyleSize == 3) {
          layerNum = 4;
        } else if(this.curStyleSize == 4 || this.curStyleSize == 5) {
          layerNum = 6;
        } else if(this.curStyleSize == 6) {
          layerNum = 1;
        }
      }

      this.curData = curDataArr;

      if(layerNum > 0) {
        //删除多余层数据
        if(this.curData[0].data.length > layerNum) {
            this.curData[0].data.splice(layerNum);
          }
      }

    //设备数据已刷新
    setTimeout(()=>{
      this.curDataRefreshFlag = true;
    },100);
  });

  Transfer.$on('deviceFunFanNum', (msg) => {
    Transfer.$off('deviceFunFanNum');
    // console.log(JSON.stringify(msg));
    this.curDataNum = msg;
    //定时检测 设备数据释放刷新
    this.numInternal = setInterval(()=>{
      this.numInternalCount++;
      //检测定时时间，超时，推出定时检测
      if(this.numInternalCount > 1000) {
        his.numInternalCount = 0;
        //清除定时器
        clearInterval(this.numInternal);
        this.numInternal = null;
        return;
      }

      //设备数据已刷新，刷新数量
      if(this.curDataRefreshFlag == true) {
        if(this.curProtocolType == 2 && 
            (this.curStyleSize == 7 || this.curStyleSize == 8)) {
            var moduleNum = msg.moduleNum;
            var layerNumArr = msg.layerNumArr;
            var fanExistsArr = msg.fanExistsArr;

            this.curData.splice(moduleNum);
            
            for(var i = moduleNum - 1; i >= 0; i--) {
              //检测模组是否有风扇，没有则删除
              if(fanExistsArr[i] == false) {
                this.curData[i].splice(i);
                continue;
              }
              var layerNum = layerNumArr[i];
              this.curData[i].data.splice(layerNum);
              
            }
          } else {
            //防止二代家用机  有多个模组灯数据
            if(this.curData.length > 1) {
              this.curData.splice(1);
            } 
            //删除多余层数据
            if(this.curData[0].data.length > msg) {
              this.curData[0].data.splice(msg);
            }
          }

        this.numInternalCount = 0;
        //清除定时器
        clearInterval(this.numInternal);
        this.numInternal = null;
      }
    }, 10);
  });
},
beforeDestroy () {
  if(this.numInternal != null) {
    clearInterval(this.numInternal);
    this.numInternal = null;
  }
  if(this.valueSubLoopTimer != null) {
    clearInterval(this.valueSubLoopTimer);
    this.valueSubLoopTimer = null;
  }
  if(this.valueAddLoopTimer != null) {
    clearInterval(this.valueAddLoopTimer);
    this.valueAddLoopTimer = null;
  }
},
methods: {

  valueSubLoopEvent(moduleIndex, index) {
    if(this.curData[moduleIndex].data[index].value <= 0) return;

    this.valueSubLoopTimer = setInterval(()=>{
      this.curData[moduleIndex].data[index].value--;
    }, 100);
  },
  valueSubLoopEndEvent(moduleIndex, index) {
    if(this.valueSubLoopTimer != null) {
      clearInterval(this.valueSubLoopTimer);
      this.valueSubLoopTimer = null;

      //更新
      this.valueChangeEvent(moduleIndex, index);
    }
    
  },

  valueAddLoopEvent(moduleIndex, index) {
    if(this.curData[moduleIndex].data[index].value >= 100) return;
    this.valueAddLoopTimer = setInterval(()=>{
      this.curData[moduleIndex].data[index].value++;
    }, 100);
  },
  valueAddLoopEndEvent(moduleIndex, index) {
    if(this.valueAddLoopTimer != null) {
      clearInterval(this.valueAddLoopTimer);
      this.valueAddLoopTimer = null;

      //更新
      this.valueChangeEvent(moduleIndex, index);
    }
    
  },

  valueChangeEvent(moduleIndex, index) {
    if(this.curOnline == 0) {
      this.tipShowFun(this, "设备离线，无效控制");
      return;
    }
    if(this.curData[moduleIndex].data[index].value == 0) this.curData[moduleIndex].data[index].switch = false;
    if(this.curData[moduleIndex].data[index].value == 100) this.curData[moduleIndex].data[index].switch = true;

    //1:一代机，2：二代机
    var ind = this.curData[moduleIndex].data[index].id;
    if(this.curProtocolType == 2) {
      var column = "fan" + ind + "Value";
      this.funModuleValueChange(column, this.curData[moduleIndex].data[index].value);
    } else {
      //一代机
      var column = "fanSpeed" + (ind - 1);
      this.funMachineValueChange(column, this.curData[moduleIndex].data[index].value);
    }
  },
  switchChangeEvent(moduleIndex, index) {
    if(this.curOnline == 0) {
      this.tipShowFun(this, "设备离线，无效控制");
      return;
    }
    if(this.curData[moduleIndex].data[index].switch == false) this.curData[moduleIndex].data[index].value = 0;
    if(this.curData[moduleIndex].data[index].switch == true) this.curData[moduleIndex].data[index].value = 100;

    //1:一代机，2：二代机
    var ind = this.curData[moduleIndex].data[index].id;
    if(this.curProtocolType == 2) {
      if(this.curData[moduleIndex].data[index].switch == true) {
        this.curData[moduleIndex].data[index].value = 100;
      } else {
        this.curData[moduleIndex].data[index].value = 0;
      }
      
      var column = "fan" + ind + "Value";
      this.funModuleValueChange(column, this.curData[moduleIndex].data[index].value);

    } else {
      //一代机
      var switchV = this.curData[moduleIndex].data[index].switch == true ? 1 : 0;
      var column = "controlFan" + ind;

      this.funMachineValueChange(column, switchV);
    }
      
  },

  //更改光强  一代机
  funMachineValueChange(column, value) {
      let that = this;
      
      var reqData = "?id=" + this.curMachine + "&column=" + column + "&value=" + value;
      this.$httpApi.updateMachine(reqData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          var msg = "";
          if(data == true) {
            msg = "修改成功";
          } else {
            msg = "修改失败";
          }
          that.tipShowFun(that, msg);

      }, function(xhr) {
        that.tipShowFun(that, "修改异常");
        
      });

    },
    //更改转速  二代机
    funModuleValueChange(column, value) {
      let that = this;
      var rackNo = 1;

      var reqData = "?machineId=" + this.curMachine + "&rackNo=" + rackNo + "&column=" + column + "&value=" + value;
      this.$httpApi.updateModuleFanValue(reqData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          var msg = "";
          if(data.message == true) {
            msg = "修改成功";
          } else {
            msg = "修改失败";
          }
          that.tipShowFun(that, msg);

      }, function(xhr) {
        that.tipShowFun(that, "修改异常");
        
      });

    },
    //消息提示
    tipShowFun(that, txt) {
      that.tipText = txt;
      that.tipdisplay = "block";
      setTimeout(() => {
        that.tipdisplay = "none";
        that.tipText = '';
      }, 1000);
    },
}

}

</script>
<!-- <style lang="scss" scoped> -->
<style lang="scss">

#deviceFunFan {
  $box-width: 450px;
  $box-height: 800px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 10px;

  

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .list {
    height: $box-height - 80px;
  }
  .list-item {
    padding:10px 20px;
  }
  .list-item-slider {
    width:250px;
    margin-left:15px;
    margin-right:15px;
  }
  .list-item-btn {
    height:30px;
    line-height:30px;
    width:40px;
    text-align:center;
    border:1px solid #999;
    border-radius:5px;

    // -moz-user-select: none;
    // -ms-user-select: none;
    // -webkit-user-select: none;
    // user-select: none;

  }

  .display-row {
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
  }

  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .fun-tip {
    position:absolute;
    left:40%;
    bottom:20px;
    padding:15px 50px;
    background-color: rgba(19, 25, 47, 0.6);
    border:2px solid rgb(46, 96, 153);
    border-radius:12px;
  }

  //左右滚动
  .el-carousel-my-no {
    height:100%;
  }
  .el-carousel-my {
    padding: 0px 20px;
    height:85%;
  }
  .el-carousel__container {
    height:100%;
  }
  
  .el-carousel__arrow--right {
    right: -25px;
  }
  .el-carousel__arrow--left {
    left: -30px;
  }

  [class*=" el-icon-"], [class^=el-icon-] {
    font-size:30px;
  }
  .el-carousel__indicators--horizontal {
    width:100%;
    text-align: center;
  }
}

</style>



