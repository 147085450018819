<template>
    <div id="navDocument1" class="document" ref="navDocument1">
        <div  class="content" ref="content">
            
            <h1>上蔬源-校园智慧农场项目实施手册</h1>
            <h1>——综合生产劳动课实施纲要</h1>

            <h3>项目背景</h3>
            <p >
                <strong>2022</strong>年<strong>3</strong>月<strong>25</strong>日教育部关于印发《义务教育课程方案和课程标准（<strong>2022</strong>年版）》的通知。
                为贯彻落实党的十八大、十九大精神，落实全国教育大会部署，全面落实立德树人根本任务，进一步深化课程改革，现将新修订的义务教育课程方案和语文等<strong>16</strong>个课程标准印
                发给教育厅局，<strong>于2022年秋季学期开始执行。</strong>
            </p>
            <p>
                《义务教育课程方案和课程标准（<strong>2022</strong>年版）》进一步优化了课程设置，九年一体化设计，注重幼小衔接、小学初中衔接，<strong>独立设置劳动课程。</strong>
            </p>
            <p>
                《义务教育<strong>劳动课程</strong>方案和课程标准（<strong>2022</strong>年版）》劳动课程要求<strong>每周1课时</strong>，学校主导家校社立体共育，
                完成义务教育劳动课程的<strong>3大类劳动内容，10个任务群</strong>，教学任务。</p>
            <img src="img/proImpManual/1.png">
            <p>
                《义务教育<strong>劳动课程</strong>方案和课程标准（<strong>2022</strong>年版）》——第六章课程实施，里面明确劳动场地：<strong>工厂、农场、专用教室，工具设备</strong>和文化氛围等；
                充分利用学校资源开展建设校园<strong>“</strong>小苗圃<strong>”</strong>、<strong>“</strong>开心农场<strong>”</strong>、<strong>“智慧农场”</strong>、<strong>“</strong>
                空中农场<strong>”</strong>等农业生产劳动实践场地。另外强调指出，组织校外劳动周，劳动周是劳动课的一部分，但<strong>劳动周活动内容与每周一节的劳动课，不能相互替代。</strong>
            </p>

            <h3>校园劳动教育现状与棘手问题</h3>
            <p><strong>1.</strong>劳动课程怎么上？从哪里入手？</p>
            <p>学校主导家校社共育，立体完成日常生活劳动、生产劳动、服务性劳动课程。</p>
            <p><strong>2.</strong>劳动场地及设备怎么解决？怎么选择？</p>
            <p>
                目前校园劳动实践场地空白，或场地安全性 季节性 单一性问题.什么劳动教育装备可以规避季节性、安全性、单一性问题，并可以融合日常生活劳动、生产劳动、服务性劳动？一种“三产融合”的
                劳动教育装备，受命而为——上蔬源-校园智慧农场。
            </p>

            <h3>关于上蔬源-校园智慧农场</h3>
            <p><strong>1.</strong>产品介绍</p>
            <p>
                上蔬源研发的“智慧植物工厂”即”校园智慧农场“是基于物联网设计的分布式智能种植设备，配备不同光谱比例和光子密度的<strong>LED</strong>或<strong>LD</strong>激光植物照明系统、
                （空气温度、空气湿度、光照强度、空气质量）四合一传感器和（水温、水位、营养指数、酸碱度）四合一传感器，触控式参数显示人机交互界面，集成蓝牙、<strong>WIFI</strong>等联网功
                能和中央处理器的主控集成电路板，移动端远程手机控制，云服务器大数据种植参数支持和监测，让学生轻松完成种植过程，变身植物专家。公司试验种植了<strong>200</strong>多种植物，
                云服务器收集存储了全套种植参数，可以帮助学校种植蔬菜、花卉、香草、中草药等不同种类植物，根据年龄特点从印象、体认、分辨、观念、模型、应用不同维度，来开展食艺、花艺、农艺、
                茶艺、药艺、园艺、木艺、陶艺、布艺、信息，科技等方面的劳动实践教育。
            </p>
            <p><strong>2.</strong>公司介绍</p>
            <p>
                上蔬源是《国家高新技术企业》、《国家科技型中小企业》、《河北省科技型中小企业》、《世界中医药学会联合会芳香健康产业分会》副会长单位、《国家智慧植物工厂创新联盟》会员单位、
                《河北省农业产业协会》副会长单位、石家庄市科普基地、石家庄市农业创新驿站。与中国农业科学院、北京农林科学院、河北省农林科学院、石家庄市农林科学院、河北农业大学、南京农业
                大学等院校和科研教育机构合作，现拥有<strong>23</strong>项外观设计专利、<strong>7</strong>项软件著作权、<strong>37</strong>项实用新型专利，<strong>3</strong>项受理
                中发明专利等<strong>70</strong>余项知识产权成果。并自主研发享有独立知识产权的<strong>4</strong>套劳动教育课程：赋能校园-生产劳动综合劳动课程、中国传统生活美学的劳动教
                育课程、创造性劳动课程、<strong>4</strong>维<strong>5</strong>育劳动课程。
            </p>

            <h3>上蔬源-校园智慧农场——助教优势分析</h3>
            <p><strong>1.</strong>传统与现代农业比较——文明回溯 探索创造 比较分析</p>
            <p><strong>2.</strong>智慧与科技农业生产——一二三产融合劳动体验</p>
            <p><strong>3.</strong>AI新技术应用与体验——未来农业生产探索</p>
            <p><strong>4.</strong>作物生长科学原理实验——学生生活与社会实际联系</p>
            <p><strong>5.</strong>延展至中国传统工艺——生活劳动能力至中国传统生活美学，热爱中国传统文化至文化自信，民族自豪感。</p>
            <img src="img/proImpManual/2.png">
            <p>“新课标”劳动课程解决方案——上蔬源-校园智慧农场综合生产劳动课（立足农业 三产融合 <strong>IA</strong>技术 传统工艺，完成“新课标”三大类劳动 <strong>10</strong>个任务群课程）</p>
            
            <h2>一、校园智慧农场项目推荐</h2>
            <h4>（一）校园智慧农场A方案——50平米基本劳动教室</h4>
            <img src="img/proImpManual/3.png">
            <img src="img/proImpManual/4.png">

            <h4>（二）校园智慧农场B方案—100平米标准劳动教室</h4>
            <img src="img/proImpManual/5.png">
            <img src="img/proImpManual/6.png">
            <img src="img/proImpManual/7.png">

            <h2>二、劳动环境文化氛围</h2>
            <h4>（一）标语</h4>

            <h3>总书记寄语</h3>
            <p>习近平总书记：</p>
            <p>要在学生中弘扬劳动精神，教育引导学生崇尚劳动、尊重劳动，懂得劳动最光荣、劳动最崇高、劳动最伟大、劳动最美丽的道理，长大后能够辛勤劳动、诚实劳动、创造性劳动。</p>

            <h3>教学篇</h3>
            <p>挖掘劳动在树德 增智 强体 育美等方面中的育人价值</p>
            <p>知行合一  学创融通</p>
            <p>做中学  学中做</p>
            <p>加强-学生生活和社会实际的关系</p>

            <h3>核心素养</h3>
            <p>树立 正确劳动观念和劳动意识</p>
            <p>形成 筹划 动手 创造性劳动能力</p>
            <p>培养 劳动习惯 塑造劳动品质</p>
            <p>培育 劳动精神 劳模精神 工匠精神</p>

            <h3>目标篇</h3>
            <p>人人都要劳动，劳动创造财富，劳动创造美好生活</p>
            <p>劳动光荣 劳动崇高 劳动美丽</p>
            <p>自己的事情自己做——第一学段（1-2年级）</p>
            <p>一分耕耘一分收获——第二学段（3-4年级）</p>
            <p>业精于勤荒于嬉——第三学段（5-6年级）</p>
            <p>劳动托起中国梦——第四学段（7-9年级）</p>

            <h4>（二）人文榜样</h4>
            <img src="img/proImpManual/8.png">
            <p>于敏：中国“氢弹之父”、核物理学家</p>
            <img src="img/proImpManual/9.png">
            <p>袁隆平：杂交水稻之父</p>
            <img src="img/proImpManual/10.png">
            <p>黄旭华：中国核潜艇之父</p>
            <img src="img/proImpManual/11.png">
            <p>屠呦呦 ：中国首位诺贝尔医学奖获得者、药学家</p>
            <!-- <img src="img/proImpManual/12.png">
            <p>钟南山 ：中国工程院院士、著名呼吸病学专家</p> -->
            <img src="img/proImpManual/13.png">
            <p>王亚平：中国空间站首位出舱女航天员</p>
            <p><span style="color:rgb(0, 122, 170);">在全世界完成过空间出舱活动的200多名航天员中，有15人为女性。</span>这一名单上增加了第<strong>16</strong>个名字——中国的女航天员王亚平。</p>
            <p>
                北京时间<strong>2021</strong>年<strong>11</strong>月<strong>8</strong>日<strong>1</strong>时<strong>16</strong>分，经过约<strong>6.5</strong>小时的出舱活动，神舟十三号航天员乘组圆满完成机械臂悬挂装置与转接件安装、舱外典型动作测试等全部既定任务，航天员翟志刚、
                航天员王亚平安全返回天和核心舱。这是空间站阶段第三次航天员出舱活动，是神舟十三号航天员乘组首次出舱活动，也是中国航天史上首次有女航天员参加的出舱活动。
            </p>

            <h4>（三）上蔬源校园智慧农场——设备原理图示</h4>
            <img src="img/proImpManual/14.png">
            <p></p>
            <img src="img/proImpManual/15.png">
            <p></p>
            <img src="img/proImpManual/16.png">
            <p></p>
            <img src="img/proImpManual/17.png">
            <p></p>
            <img src="img/proImpManual/18.png">

            <h2>三、课程综述</h2>
            <p><strong>1.</strong>对应“新课标”1-9年级全覆盖——<strong>基础依据与覆盖阶段</strong></p>
            <p>上蔬源-校园智慧农场综合生产劳动课程，对应《义务教育劳动课程方案与标准2022版》，课程内容与结构1-9年级全覆盖；</p>
            <p><strong>2.</strong>立足农业 三产融合 IA应用 传统美学——<strong>课程内容</strong></p>
            <p>上蔬源-校园智慧农场综合生产劳动课程——立足农业 三产融合 IA应用 传统美学（传统工艺）；</p>
            <p><strong>3.</strong>立德为宗 成长为本 教育为体 实践为相 素养为用——<strong>施教原则</strong></p>
            <p>上蔬源-校园智慧农场综合生产劳动课程——立德树人为根本目标，关注孩子的成长特征，有劳有教为课程之体，实践为劳动和教育的实践，劳动核心素养培养为用。</p>
            <p><strong>4.</strong>体认触摸 - 实践探索 - 纵横比较 - 系统方法——<strong>课堂方式</strong></p>
            <p>上蔬源-校园智慧农场综合生产劳动课程——课堂方式：体认触摸（1-2）实践探索（3-4） 纵横比较（5-6）系统方法（7-9）；</p>
            <p><strong>5.</strong>从科技出发，回溯过去，创造未来——<strong>课程外延</strong></p>
            <p>
                上蔬源-校园智慧农场综合生产劳动课程——从科技出发，驱动孩子的好奇心、探索欲，了解当下生活生产，理解科技影响力；启发孩子的创造力，塑造他们的创造精神，
                实践他们的创造性劳动成果与蓝图，为中华民族未来奠定更扎实的社会主义接班人基础，回溯过去5000年文明，了解我们的辛勤、诚实劳动，理解我们民族的价值观与
                生活美学，增强孩子们的民族归属感、爱国、爱党、爱人民的意志与决心。
            </p>

            <h2>四、代理商产品咨询内容汇编</h2>
            <img src="img/proImpManual/19.png">
            <img src="img/proImpManual/20.png">
            <img src="img/proImpManual/21.png">
            <img src="img/proImpManual/22.png">

            <h2>五、赋能校园智慧农场IA技术介绍</h2>
            <p>
                “校园智慧农场”即绿色“科技种植设备”是由上蔬源在电工电子教学实验中和信号与图像处理研究下，带领“0”碳-AI赋能“校园智慧农场”在AI实验室中研发出的。
                通<span style="color:rgb(255, 60, 60);">过智能控制系统，实现蔬菜培育的全程自动化，绿色环保零排放。“校园智慧农场”以人工智能图像识别、清洁能
                源发电等技术为支撑，以相关传感器和嵌入式系统为载体，打造了“零碳”智能种植机。太阳能发电技术实现环保低碳；<strong>AI图像识别技术实现对作物的病虫
                害实时监控与生长阶段状态记录；光源调控技术实时调控光照强度和波长以适应作物生长的不同阶段；营养液配比技术实现零农残；嵌入式控制系统实现作物生长环境
                的整体调节和状态联网。</strong></span>
            </p>
            <p>
                一台“智能植物生长机”有四排种植架，每排种植架上有24个槽位，只要将根部固定在海绵里的菜苗塞进种植机的每个槽位，就能够实现蔬菜的自主栽培。
                每个槽位下都有联通管道定时循环灌溉进行水培，为蔬菜生长提供水和营养物质，智能植物生长机模型见图1。
            </p>
            <div style="text-align:center;">
                <img src="img/proImpManual/23.png">
                <p>图1	“0”碳智能植物生长机</p>
            </div>
            <p>
                <strong>种植机拥有完备的嵌入式控制系统，通过多种传感器进行信息收集与整合，实时监测植物的生长环境，如温度、湿度、空气质量等；</strong>监控营养液的水位、水温、电导率、
                智能控制水泵启停，实现营养液精细添加等。同时，嵌入式控制系统通过物联网技术连接终端APP，随时随地监控掌握蔬菜生长动态。嵌入式控制系统与终端APP示意图见图2。
            </p>
            <div style="text-align:center;">
                <img src="img/proImpManual/24.png">
                <p>图2	嵌入式控制系统与终端APP示意图</p>
            </div>
            <p>
                植物生长离不开光合作用。实验室的“菜田”上，<strong>“日光”是由多光质组合成的植物LED节能光源，见图3。通过不同光质组合和光强、光周期调控定制出蔬菜所需的“光配方”，
                调节适合蔬菜生长的光照强度和波长，满足蔬菜全生育期光合作用需要。</strong>一般种植蔬菜的成熟周期是60天，在该种植机上只需要40天就可以收获。而且，种植机不受季节、
                环境等外界因素的影响，能够实现全年无间断种植，收获“源源不断”的绿色蔬菜。
            </p>
            <div style="text-align:center;">
                <img src="img/proImpManual/25.png">
                <p>图3	多光质组合的植物LED节能光源</p>
            </div>
            <p>
                在蔬菜生长的过程中，<strong>上蔬源使用AI图像识别技术自动识别出不同蔬菜种类和生长阶段，从而全流程无需人工干预，大幅度降低蔬菜成本，甚至连菜叶上出现的病斑、
                虫眼这些细小的信息都逃不过AI 图像识别的“慧眼”，</strong>图像识别技术见图4。
            </p>
            <div style="text-align:center;">
                <img src="img/proImpManual/26.png">
                <p>图4	AI图像识别技术自动识别</p>
            </div>
            <p>
                此外，种植机还能通过太阳能提供电力，并配备有蓄电池确保阴天也能稳定供电，使整个过程更加环保、节能。在蔬菜培养过程中，完全不需要使用农药，通过科学配比营养液实现
                产品纯绿色，“零农残”，设计理念见图5。
            </p>
            <div style="text-align:center;">
                <img src="img/proImpManual/27.png">
                <p>图5	“科技蔬菜”理念</p>
            </div>
            <p>
                上蔬源团队自研发以来先后已经在种植机上实验种植了千余种作物，包括中药、香草、花卉、谷薯、蔬菜，到目前为止已成功培育了200余种具有独家光配方、营养参数的特色作物，
                尤其蔬菜种类有生菜、绿奶油、红奶油、红生菜和格林广受食客好评。
            </p>

            <h2>六、农业技术赋能支持</h2>
            <p><strong>1.</strong>系统智慧农业种植培训</p>
            <p><strong>2.</strong>经过多年种植实验，200多种植物支持。</p>

            <h2>七、课程大纲</h2>
            <p>注：另附文件</p>

            <h2>八、上蔬源劳动课程大纲依据</h2>
            <img src="img/proImpManual/28.png">

            <h2>九、课程实施方法</h2>
            <h4>（一）劳动任务方式</h4>
            <p><strong>1.</strong>劳动任务名称</p>
            <p><strong>2.</strong>任务描述</p>
            <p><strong>3.</strong>任务要求</p>
            <p><strong>4.</strong>评价标准</p>
            <h3>课件（例-1）</h3>
            <img src="img/proImpManual/29.png">
            <p></p>
            <img src="img/proImpManual/30.png">
            <p></p>
            <img src="img/proImpManual/31.png">

            <h4>（二）综合劳动课程课堂实践方式</h4>
            <img src="img/proImpManual/32.png">
            <h3>课件（例）</h3>
            <p>一年级课件《认识蔬果——我与蔬果交朋友》</p>
            <p>1.想一想</p>
            <img src="img/proImpManual/33.png">
            <p>2.说一说</p>
            <img src="img/proImpManual/34.png">
            <p>3.猜一猜</p>
            <img src="img/proImpManual/35.png">
            <p>4.试一试</p>
            <img src="img/proImpManual/36.png">
            <p>5.行一行</p>
            <img src="img/proImpManual/37.png">

            <H4>（三）教案（例）</H4>
            <p>第一学段（1-2年级）上学期教师教案</p>
            <p>课题：</p>
            <p>《种植与蔬果——我与蔬果交朋友》</p>
            <h5>一、教学对象</h5>
            <p>第一学段（1-2年级）</p>
            <h5>二、教学目标</h5>
            <p><strong>1.</strong>了解认识蔬果的基本形态，生长习性、整体构造，及栽培和生长方式，和食用部位，等基本百科知识；</p>
            <p><strong>2.</strong>通过水培和土培种植比较，让孩子们了解水培与土培的共同原理及作物生长不同条件和规律；</p>
            <p><strong>3.</strong>本次课程教会孩子使用科技种植设备，种植操作流程，理解科技种植原理，并亲手种下自己喜欢的蔬果，用责任浇灌未来果实；</p>
            <p><strong>4.</strong>通过种植劳动实践让孩子热爱科学，亲近自然，理解合作的意义；</p>
            <p><strong>5.</strong>孩子通过家庭践行每周三餐的蔬果进食情况，培养健康的饮食习惯。</p>
            <h5>三、教学重点</h5>
            <p><strong>1.</strong>认识蔬果-体认蔬果-种下蔬果-呵护蔬果-喜欢蔬果-收获蔬果；</p>
            <p><strong>2.</strong>植物生长原理，无土栽培种植，理解科技。</p>

            <h4>（四）课程实施过程中相关表单</h4>
            <p><strong>1.</strong>劳动实践课程任务单</p>
            <img src="img/proImpManual/38.png">
            <p><strong>2.</strong>种植实践记录卡</p>
            <img src="img/proImpManual/39.png">
            <p><strong>3.</strong>学校主导——家庭劳动清单</p>
            <img src="img/proImpManual/40.png">

            <h2>十、劳动课程阶段综合评价（即期末、其中考试）</h2>
            <img src="img/proImpManual/41.png" @load="imageLoad">
            
            <p style="margin-top:50px;">上蔬源义务教育中心</p>
            <p>2022年8月5日</p>
        </div>
        
    </div>
</template>

<script>

import bScroll from 'better-scroll'

export default {
    data() {
        return {
            bscroll: null
        }
    },
    components: {

    },
    mounted() {
        // this.bscroll = new bScroll(this.$refs.navDocument, {
        //     probeType: 2,
        // });
        // this.bscroll.on("scroll", position=>{
        //     console.log(position);
        // });
        
    },
    beforeDestroy () {
        if(this.bscroll != null) {
            this.bscroll.disable();
            this.bscroll.destroy();
            this.bscroll = null;
        }
    },
    methods: {
        imageLoad() {
            if(this.bscroll != null) {
                this.bscroll.disable();
                this.bscroll.destroy();
                this.bscroll = null;
            }
            this.bscroll = new bScroll(this.$refs.navDocument1, {
                probeType: 2,
                disableMouse: false,//启用鼠标拖动
                disableTouch: false//启用手指触摸
            });
            // this.bscroll.on("scroll", position=>{
            //     console.log("position=" + position);
            // });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/document.scss';

</style>