<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import { formatTime } from '../../../../utils/index.js'
import Transfer from "../../../../utils/transfer.js";

//工程模组 环境传感器
//1 主板数据 2~11 子系统数据
var moduleDataArr = [{},{},{},{},{},{},{},{},{},{},{}];

export default {
  data () {
    return {
      cdata: {
        times: [ "1", "2",  "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
        humidityData: [10, 20, 10, 5, 20, 30, 35, 20, 23, 25, 22, 21, 24, 26, 28, 20, 19, 18, 20, 24, 25, 23, 25, 28],
        tempData: [20, 25, 25, 26, 22, 20, 15, 18, 16, 28, 30, 33, 35, 32, 31, 36, 28, 25, 20, 14, 15, 13, 11, 12],
        brightData: [10, 20, 10, 5, 20, 30, 35, 20, 23, 25, 22, 21, 24, 26, 28, 20, 19, 18, 20, 24, 25, 23, 25, 28]
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    var globalData = this.$store.state.globalData;
    // if(globalData.curProtocolType == 2) {
    //   //工程模组协议
    //   // this.getSensorHistoryData();
    //   this.getSensorHistoryDataMulti();
    // } else {
    //   //家用机协议
    //   this.getSensorHistoryDataWithHour();
    // }
    
    Transfer.$on('buttomLeft-buttomLeft', (msg) => {
      // console.log(msg);
      if(globalData.curProtocolType == 2) {
        //工程模组协议
        // this.getSensorHistoryData();
        this.getSensorHistoryDataMulti();
      } else {
        //家用机协议
        this.getSensorHistoryDataWithHour();
      }
    });

    //切换子传感器
    Transfer.$on('buttomLeft-buttomLeft-child', (msg) => {
      if(globalData.envSensorNum > 1) {
        var index = globalData.curEnvSensorNo;
        var datas = moduleDataArr[index];
        this.getSensorHistoryDataFun(this, datas)
      }
    });

  },
  methods: {
    // 根据自己的业务情况修改
    arrayContains(array, item){
      for(var i = 0; i < array.length; i++){
        if(array[i] == item){
          return true;
        }
      }
      return false;
    },
    formatData (list) {
      var datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[], statusEc:[], waterTemperature:[], waterlevel:[], ph:[]};
      // var datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[]};
      for(var i = 0; i < list.length; i++){
        var sensorData = list[i];
        if(sensorData.createTime == null) continue;
        
        var time = sensorData.createTime.substring(11, 13);
        
        if(this.arrayContains(datas.times, time)==false){
          datas.times.push(time);
          for(var property in sensorData){
            if(property=="innerTemp"){
              var tempData = Math.round(sensorData.innerTemp * Math.pow(10, 2)) / Math.pow(10, 2);
              datas.innerTemperature.push(tempData);
            }else if(property=="innerHumidity"){
              var humidityData = Math.round(sensorData.innerHumidity * Math.pow(10, 2)) / Math.pow(10, 2);
              datas.innerHumidity.push(humidityData);
            }else if(property=="innerBright"){
              var brightData = Math.round(sensorData.innerBright * Math.pow(10, 2)) / Math.pow(10, 2);
              datas.innerBright.push(brightData);
            }
            // else if(property=="statusEC"){
            //   datas.statusEc.push(cep.format.fomatFloat(sensorData.statusEC,2));
            // }else if(property=="waterTemp"){
            //   datas.waterTemperature.push(cep.format.fomatFloat(sensorData.waterTemp,2));
            // }else if(property=="waterLevel"){
            //   datas.waterlevel.push(cep.format.fomatFloat(sensorData.waterLevel / 10,2));
            // }else if(property=="statusPH"){
            //   datas.ph.push(cep.format.fomatFloat(sensorData.statusPH,2));
            // }
          }
        }
        
      }
      
      return datas;
    },
    //存储传感器数据
    getSensorHistoryDataSave(curNo, datas) {
      if(datas == null) {
        datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[], statusEc:[], waterTemperature:[], waterlevel:[], ph:[], maxDataArr:[]};
      }
      moduleDataArr[curNo - 1] = datas;
    },
    //获取数据，渲染页面
    getSensorHistoryDataFun(that, datas) {
        that.cdata.times = datas.times;
        that.cdata.tempData = datas.innerTemperature;
        that.cdata.humidityData = datas.innerHumidity;
        that.cdata.brightData = datas.innerBright;
    },
    //获取传感器数据
    getSensorHistoryData (that, groupId, curNo, resData) {
      // var globalData = this.$store.state.globalData;
      // if(globalData.curMachine == '') return;
      
      // let that = this;
      
      // var curDate = new Date();
      // curDate.setHours(0);
      // curDate.setMinutes(0);
      // curDate.setSeconds(0);
      // var curDateDay = formatTime(curDate, 'HH:mm:ss')
      // var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
      // var currentDateStr = curDateYear + " " + curDateDay;
      
      // var groupId = 1;
      // var machineId = globalData.curMachine; //"872204150001"; //
      // var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
      this.$httpApi.getMachineSensorHistory(resData, function(data) {
        // var data = d.message;
        // console.log(JSON.stringify(data)); 
        if(data == null || data.length == 0) {
          // that.getSensorHistoryDataSave(groupId, null);
          that.getSensorHistoryDataSave(curNo, null);
          return;
        }

        var datas = that.formatData(data);
        // console.log(JSON.stringify(datas));

        //存储传感器数据
        // that.getSensorHistoryDataSave(groupId, datas);
        that.getSensorHistoryDataSave(curNo, datas);
        //渲染页面
        that.getSensorHistoryDataFun(that, datas);
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });
    },
    //获取多组
    getSensorHistoryDataMulti () {
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;
      
      let that = this;
      
      var curDate = new Date();
      // curDate.setHours(0);
      // curDate.setMinutes(0);
      // curDate.setSeconds(0);
      var curDateDay = formatTime(curDate, 'HH:mm:ss')
      var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
      var currentDateStr = curDateYear + " " + curDateDay;
      var machineId = globalData.curMachine;  //"872204150001"; //
      // var groupId = 1;
      // if(globalData.envSensorNum > 1) {
      //   groupId = globalData.curEnvSensorNo + 1;
      // }
      // //1 主板数据 2~11 子系统数据
      // var groupId = 1;
      // if(globalData.envSensorNum > 1) {
      //   for(var i = 1; i <= globalData.envSensorNum; i++) {
      //     groupId = i + 1;
      //     var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
      //     that.getSensorHistoryData(that, groupId, resData);
      //   }
      // } else {
      //   var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
      //   that.getSensorHistoryData(that, groupId, resData);
      // }
      
      //工程模组
      //1 主板数据 2~11 子系统数据
      var groupId = 1;
      if(globalData.curStyleSize == 7 || globalData.curStyleSize == 8) {
        for(var i = 1; i <= globalData.envSensorNum; i++) {
          var moduleIndex = globalData.envSensorList[i-1];
          groupId = moduleIndex + 1;
          var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
          that.getSensorHistoryData(that, groupId, i, resData);
        }
      } else {
        var resData = "?groupId=" + groupId + "&machineId=" + machineId + "&date=" + currentDateStr;
        that.getSensorHistoryData(that, groupId, 1, resData);
      }
      
    },

    //----家用机----
    formatData01 (list) {
      // var datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[], statusEc:[], waterTemperature:[], waterlevel:[], ph:[]};
      var datas = {times:[], innerTemperature:[], innerHumidity:[], innerBright:[]};
      for(var i = 0; i < list.length; i++){
        var sensorData = list[i];
        if(sensorData.collectTime == null) continue;
        
        var time = sensorData.collectTime.substring(11, 13);
        
        if(this.arrayContains(datas.times, time)==false){
          datas.times.push(time);
          for(var property in sensorData){
            if(property=="statusAirTemperature"){
              var tempData = Math.round(sensorData.statusAirTemperature * Math.pow(10, 2)) / Math.pow(10, 2);
              datas.innerTemperature.push(tempData);
            }else if(property=="statusAirHumidity"){
              var humidityData = Math.round(sensorData.statusAirHumidity * Math.pow(10, 2)) / Math.pow(10, 2);
              datas.innerHumidity.push(humidityData);
            }else if(property=="statusIllumination"){
              var brightData = Math.round(sensorData.statusIllumination * Math.pow(10, 2)) / Math.pow(10, 2);
              datas.innerBright.push(brightData);
            }
            
          }
        }
        
      }
      
      return datas;
    },
    //获取传感器数据--家用机
    getSensorHistoryDataWithHour () {
      var globalData = this.$store.state.globalData;
      if(globalData.curMachine == '') return;
      
      let that = this;
      
      var curDate = new Date();
      // curDate.setHours(0);
      // curDate.setMinutes(0);
      // curDate.setSeconds(0);
      var curDateDay = formatTime(curDate, 'HH:mm:ss')
      var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
      var currentDateStr = curDateYear + " " + curDateDay;
      
      var machineId = globalData.curMachine; //"872204150001"; //
      var resData = "?machineCode=" + machineId + "&date=" + currentDateStr;
      this.$httpApi.getMachineSensorDataWithHour(resData, function(data) {
        
        // var data = d.message;
        // console.log(JSON.stringify(data)); 
        if(data == null || data.length == 0) return;

        var datas = that.formatData01(data);
        // if(datas.times.length <= 5) {
        //   that.getSensorHistoryDataWithHourSecond(that, datas);
        //   return;
        // }

        that.cdata.times = datas.times;
        that.cdata.tempData = datas.innerTemperature;
        that.cdata.humidityData = datas.innerHumidity;
        that.cdata.brightData = datas.innerBright;
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });

    },
    getSensorHistoryDataWithHourSecond (that, preDatas) {
      var globalData = that.$store.state.globalData;
      if(globalData.curMachine == '') return;
      
      
      var curDate = new Date();
      // curDate.setHours(0);
      // curDate.setMinutes(0);
      // curDate.setSeconds(0);
      curDate.setDate(curDate.getDate() - 1);
      var curDateDay = formatTime(curDate, 'HH:mm:ss')
      var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
      var currentDateStr = curDateYear + " " + curDateDay;
      
      var machineId = globalData.curMachine; //"872204150001"; //
      var resData = "?machineCode=" + machineId + "&date=" + currentDateStr;
      this.$httpApi.getMachineSensorDataWithHour(resData, function(data) {
        
        // var data = d.message;
        //console.log(JSON.stringify(data)); 
        if(data == null || data.length == 0) return;

        var datas = that.formatData01(data);
        console.log(JSON.stringify(preDatas.times));
        console.log(JSON.stringify(datas.times));
        
        datas.times = datas.times.concat(preDatas.times);
        datas.innerTemperature = datas.innerTemperature.concat(preDatas.innerTemperature);
        datas.innerHumidity = datas.innerHumidity.concat(preDatas.innerHumidity);
        datas.innerBright = datas.innerBright.concat(preDatas.innerBright);
        console.log(JSON.stringify(datas.times));

        that.cdata.times = datas.times;
        that.cdata.tempData = datas.innerTemperature;
        that.cdata.humidityData = datas.innerHumidity;
        that.cdata.brightData = datas.innerBright;
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });

    }


  },
  
  
};
</script>

<style lang="scss" scoped>
</style>