<template>
  <div id="centerLeft2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">设备概览</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <!-- <CenterLeft1Chart /> -->
        <CenterLeft1Chart />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex jc-center">
            <span class="coin">{{ item.text }} : {{ item.number.number[0] }}台</span>
          </div>
          
        </div>
      </div>
      <!-- <div class="bottom-data">
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex">
            <span class="coin"></span>
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p class="text" >
            {{ item.text }}
            <span class="colorYellow">(台)</span>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from '@/components/echart/centerLeft/centerLeft1Chart'

export default {
  data() {
    return {
      numberData: [
        // {
        //   number: {
        //     number: [0],
        //     toFixed: 0,
        //     textAlign: 'center',
        //     content: '{nt}',
        //     style: {
        //       fontSize: 24
        //     }
        //   },
        //   text: '设备在线数量'
        // },
        {
          number: {
            number: [25685],
            toFixed: 0,
            textAlign: 'center',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '设备总数'
        }
      ]
    }
  },
  components: {
    CenterLeft1Chart
  },
  mounted() {
    // this.changeTiming()
    // this.getData();
  },
  methods: {
    changeTiming() {
      
      setInterval(() => {
        this.changeNumber()
      }, 3000)
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index
        item.number = { ...item.number }
      })
    },
    getData:function(){
      let that = this;

      var resData = {'adminId':''};
      this.$httpApi.getMachineNum(resData, function(data) {
        // console.log(JSON.stringify(data)); 
        var totalNum = ((data.offline == null | data.offline == '') ? '0' : data.offline) 
                        + ((data.online == null | data.online == '') ? '0' : data.online);
        // that.numberData[0].number.number[0] = ((data.online == null | data.online == '') ? '0' : data.online);
        // that.numberData[1].number.number[0] = totalNum;
        that.numberData[0].number.number[0] = totalNum;

        that.numberData = { ...that.numberData };
      }, function(xhr) {
        console.log(xhr.responseText);
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>

#centerLeft2 {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  // width: $box-width;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    margin-top:10px;
    .item-box {
      & > div {
        padding-right: 5px;
      }
      font-size: 14px;
      float: right;
      position: relative;
      width: 100%; //50%;
      color: #d3d6dd;
      .dv-digital-flop {
        // width: 120px;
        width:100%;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 10px; //color: #3de7c9 ;//#ffc107;6px;
        font-size: 20px;
        color: #3de7c9 ;//#ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
}
</style>
