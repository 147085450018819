<template>
    <div id="cameraControl" ref="cameraControl">
        <div class="content-box">
            <div id="container" :width="{width}" :height="{height}" style="background-color: #000000;">
                <div id="video-container-control"></div>
            </div>

            <div class="control-fun">
                <div class="display-flex-column" :style="{display:controlDisplay}">
                    <div class="ptz-title">云 台</div>
                    <div class="display-flex-column">
                        <span id="ptzUp" class="icon iconfont icon-fangxiangsanjiao-xiangshang" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                        <div class="display-flex-row" style="margin-top:40px; margin-bottom:50px;">
                            <span id="ptzLeft" class="icon iconfont icon-fangxiangsanjiao-xiangzuo iconfont-left-right" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                            <span id="ptzRight" class="icon iconfont icon-fangxiangsanjiao-xiangyou iconfont-left-right" style="margin-left:80px;" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                        </div>
                        <span id="ptzDown" class="icon iconfont icon-fangxiangsanjiao-xiangxia" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                    </div>
                    <div class="display-flex-row" style="margin-top:60px;">
                        <span id="ptzIn" class="icon iconfont icon-zengjia" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                        <span id="ptzOut" class="icon iconfont icon-jianshao" style="margin-left:60px;" @mousedown="ptzStartEvent" @mouseup="ptzEndEvent"></span>
                    </div>
                </div>
                
                <div class="return-btn" @click="returnBtnEvent">返回</div>

            </div>
            
        </div>
        
    </div>
</template>
<script>

import EZUIKit from 'ezuikit-js';
import Transfer from "../utils/transfer";
import '@/assets/iconfont/iconfont.css';
var myWebcam = [], myWebcamEleArr = [], myWebcamShowId = null;


export default {
    data() {
        return {
            controlDisplay: 'flex',
            width:1280,
            height:720,
            //摄像头列表  摄像头数组索引
            srcIndex: null,
            //播放摄像头 解码索引
            index: null,
            //设备编号，若是双目摄像头，用于唯一设备编号
            uniqueDeviceSerial: '',
            // deviceSerial: '13018500401320000002',
            // playSsrc:'0130000002',
            deviceSerial: '',
            audioPlay: 0,
            reload: false,
            replayerTiming: null,
            replayer: false,
            player: null,
            direction: null,
        }
    },
    components: {
        
    },
    mounted() {
        // //采取异步async
        // (async () => {
        //     this.loadFFmpegFun();
        // })();
        
        Transfer.$on('cameraControl', (msg) => {
            this.uniqueDeviceSerial = msg.uniqueDeviceSerial;
            this.deviceSerial = msg.deviceSerial;
            this.monitorUrl = msg.monitorUrl;
            this.audioPlay = msg.audioPlay;
            this.srcIndex = msg.srcIndex;
            this.index = msg.index;
            if(msg.ptz == 0) {
                this.controlDisplay = 'none';
            } else {
                this.controlDisplay = 'flex';
            }
            this.startPlayer();
        });
    },
    beforeDestroy () {
        this.destroyEvent();
    },
    methods: {
        changeTiming() {
            this.replayerTiming = setInterval(() => {
                if(this.replayer == false) return;
                this.player.play();
                
            }, this.$GLOBAL.webcamReplayerTiming)
        },
        destroyEvent() {
            clearInterval(this.replayerTiming);
            this.replayerTiming = null;

            if(this.player != null) {
                this.player.stop();
            }
            this.player = null;
            this.srcIndex = null;
            this.index = null;
        },
        
        startPlayer() {
            var globalData = this.$store.state.globalData;
            var videoEleId = "video-container-control";
            
            //开始播放监控
            this.player = new EZUIKit.EZUIKitPlayer({
                id: videoEleId, // 视频容器ID
                accessToken: globalData.accessToken,
                url: this.monitorUrl,
                width:this.width, //260,
                height:this.height, //330,
                audio: this.audioPlay,
                autoplay:true,
                handleSuccess: function(msg) {
                    //console.log("-------------------" + JSON.stringify(msg));
                    this.replayer = false;
                },
                handleError: function(msg) {
                    //{"retcode":9048,"msg":"免费版并发数达到上限，请升级企业版使用多并发能力","id":"video-container0","type":"handleError"}
                    //console.log("----------------msg=" + JSON.stringify(msg));
                    //https://open.ys7.com/help/377
                    //10002: accessToken过期或异常
                    //9048: 开放平台路数超限
                    //5404: 设备不在线
                    //6519: 设备推流链路网络不稳定
                    //6520: 设备推流链路数据格式异常、网络异常
                    //1003: 取流网络断开
                    //5405: 流媒体向设备发送或接受信令超时/cas响应超时
                    if(msg == null) return;
                    if(msg.retCode == 10002) {
                        this.reload = true;
                    } 
                    else if(msg.retcode == 5404 || msg.retcode == 6519 || msg.retcode == 6520 || msg.retcode == 1003 || msg.retcode == 5405 || msg.retcode == 9048) {
                        this.replayer = true;
                    }
                }
                
            });
                
        },
        
        //方向控制 开始
        ptzStartEvent(e) {
            var id = e.target.id;
            var param = id.substring("ptz".length).toLowerCase();
            document.getElementById(id).classList.add("ptz-selected");

            var globalData = this.$store.state.globalData;
            var accessToken = globalData.accessToken;
            var deviceSerial = this.uniqueDeviceSerial;
            var channelNo = 1;
            //0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距，16-自动控制
            var direction = "";
            if(param == "up") direction = 0;
            else if(param == "down") direction = 1;
            else if(param == "left") direction = 2;
            else if(param == "right") direction = 3;
            else return;
            //记录云台控制方向，用于停止
            this.direction = direction;

            //云台速度：0-慢，1-适中，2-快，海康设备参数不可为0
            var speed = 1;
            
            var type = "start";
            var resData = "?accessToken=" + accessToken + "&deviceSerial=" + deviceSerial + "&channelNo=" + channelNo + "&direction=" + direction + "&speed=" + speed;
            this.$httpApi.setEZUIKitPtz(type, resData, function(d) {
                if(d.code == 200) {
                    console.log("开始成功");
                } else {
                    console.log("开始失败");
                }
            }, function(xhr) {
                console.log(xhr.responseText);
                console.log("开始异常");
            });
            
        },
        //方向控制 结束
        ptzEndEvent(e) {
            var deviceSerial = this.deviceSerial;
            var id = e.target.id;
            document.getElementById(id).classList.remove("ptz-selected");
            
            
            var globalData = this.$store.state.globalData;
            var accessToken = globalData.accessToken;
            var deviceSerial = this.uniqueDeviceSerial;
            var channelNo = 1;
            //0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距，16-自动控制
            var direction = this.direction;
            

            var type = "stop";
            var resData = "?accessToken=" + accessToken + "&deviceSerial=" + deviceSerial + "&channelNo=" + channelNo + "&direction=" + direction;
            this.$httpApi.setEZUIKitPtz(type, resData, function(d) {
                if(d.code == 200) {
                    console.log("停止成功");
                } else {
                    console.log("停止失败");
                }
            }, function(xhr) {
                console.log(xhr.responseText);
                console.log("停止异常");
            });
            
        },
        //返回按钮
        returnBtnEvent() {
            var sendData = {};
            sendData.switch = false;
            sendData.srcIndex = this.srcIndex;
            //结束播放事件
            this.destroyEvent();
            //隐藏窗口
            Transfer.$emit('index-cameraControl', sendData);
        },
    }
}

</script>
<style lang="scss" scoped>
@import '../assets/scss/camera1.scss';
</style>
