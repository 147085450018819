<template>
  <div id="centerRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">监控</span>
          <dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      <div id="webcam-list" class="d-flex jc-center my-video">
        
      </div>
    </div>
  </div>
</template>

<script>

import Transfer from "@/utils/transfer";
// import ffmpegModule from "@/utils/ffmpegFun";
import WebglScreen from "@/utils/WebglScreen.js";
var myWebcam = [], myWebcamShowId = null;

//const ffmpegModule = import("@/utils/ffmpegFun");

export default {
  data() {
    return {
      width:260,
      height:330,
      refreshTiming: null,
      replayerTiming: null,
      wasm: null,
      wasmLoad: false,
    }
  },
  components: {
    // CenterLeft2Chart
  },
  created() {
    window.gbControlEvent = this.gbControlEvent;
  },
	mounted(){
    //调用函数
    var globalData = this.$store.state.globalData;
    if(globalData.webcamShow != 1) {
      //未配置摄像头，返回
      //防止报错，还需加载组件，报错：domObserver.disconnect is not a function at VueComponent.unbindDomResizeCallback
      return;
    }
    
    //采取异步async
    (async () => {
      this.initWasm();
    })();
    
   
    //this.getWebcamList(),
    this.changeTiming();
    Transfer.$on('centerRight2', (msg) => {
      // console.log(msg);
      this.refreshWebcamShow();
    });
    //重新播放
    Transfer.$on('centerRight2-replay', (msg) => {
      var srcIndex = msg.srcIndex;
      myWebcam[srcIndex].worker.postMessage('play');
    });
	},
  beforeDestroy () {
    if(this.refreshTiming != null) {
      clearInterval(this.refreshTiming);
    }
    if(this.replayerTiming != null) {
      clearInterval(this.replayerTiming);
    }
    this.refreshTiming = null;
    this.replayerTiming = null;
    this.wasmLoad = false;
    

    for(var i = 0; i < myWebcam.length; i++) {
      // if(myWebcam[i].ws == null || myWebcam[i].ws == undefined) continue;
      if(myWebcam[i].worker == null || myWebcam[i].worker == undefined) continue;
      //发送结束播放指令
      this.sendWebcamCmd(myWebcam[i].webcamSerial, "bye");
      //myWebcam[i].ws.close();
      myWebcam[i].worker.postMessage('close');
      //清除canvas
      myWebcam[i].webglRender.destroy();
    }
    myWebcam = [];
    myWebcamShowId = null;
    
    // console.log(myWebcam);
  },
  methods: {
    //采取异步async await 方式调用
    async initWasm (){
      // const wasmModule = await ffmpegModule;
      // //退出登录再登陆，wasm已经加载，需再定时中直接使用，如果刷新页面，则会重新加载
      // //防止wasm已经加载，加载状态没有回调
      // setTimeout(() => {
      //   if(this.wasmLoad == false) {
      //     this.wasm = wasmModule.default;
      //     this.getWebcamList();
      //   }
      // }, this.$GLOBAL.wasmLoadDelayTiming)

      // //wasm加载
      // wasmModule.default.onRuntimeInitialized = () => {
      //   this.wasmLoad = true;
      //   this.wasm = wasmModule.default;
      //   this.getWebcamList();
      // }
      
      this.getWebcamList();
    },

    changeTiming() {
      // this.refreshTiming = setInterval(() => {
      //   this.getWebcamList();
      // }, this.$GLOBAL.webcamRefreshTiming)

      // this.replayerTiming = setInterval(() => {
      //   for(var i = 0; i < myWebcam.length; i++) {
      //     if(myWebcam[i].replayer == false) return;
      //     myWebcam[i].ws.play();
      //   }
      // }, this.$GLOBAL.webcamReplayerTiming);

    },
    
    refreshWebcamShow() {
      if(myWebcamShowId == null) {
        if(myWebcam.length > 0) {
          var toShowEleId = "";
          for(var i = 0; i < myWebcam.length; i++) {
            if(globalData.curWebcamSerial == myWebcam[i].webcamSerial) {
              toShowEleId = myWebcam[i].eleId;
              myWebcamShowId = i;
            }
          }
          document.getElementById(toShowEleId).setAttribute('style', "display:inline-block;");
          myWebcam[myWebcamShowId].worker.postMessage('play');
        }
        return;
      }
      var index = myWebcamShowId;
      
      var globalData = this.$store.state.globalData;
      
      if(myWebcam.length == 0 || index == null || index > myWebcam.length)  return;
      if(globalData.curWebcamSerial == myWebcam[index].webcamSerial) return;


      var curShowEleId = myWebcam[index].eleId;
      var toShowEleId = "";
      for(var i = 0; i < myWebcam.length; i++) {
        if(globalData.curWebcamSerial == myWebcam[i].webcamSerial) {
          toShowEleId = myWebcam[i].eleId;
          myWebcamShowId = i;
        }
      }
      
      if(toShowEleId == '') return;
      
      document.getElementById(curShowEleId).setAttribute('style', "display:none;");
      document.getElementById(toShowEleId).setAttribute('style', "display:inline-block;");

      myWebcam[index].worker.postMessage('stop');
      myWebcam[myWebcamShowId].worker.postMessage('play');
    },
    getWebcamList() {
      let that = this;
      var globalData = this.$store.state.globalData;
      if(globalData.curGroupId == null && globalData.curGroupName == '') return;
      
      var resData = "?groupId=" + globalData.curGroupId + "&groupName=" + globalData.curGroupName;
      if(globalData.curGroupId == null) {
        resData = "?groupId=&groupName=" + globalData.curGroupName;
      }
      // console.log(JSON.stringify(resData));
      this.$httpApi.getWebCamList(resData, function(d) {
        // console.log(JSON.stringify(d)); 
        if(d == null || d.message == null || d.message.length == 0) return;

        var data = d.message;
        if(globalData.accessToken == data[0].accessToken && myWebcamShowId != null) return;

        //开始打开摄像头
        globalData.accessToken = data[0].accessToken;
        
        //清空元素
        var webcamListEle = document.getElementById('webcam-list');
        webcamListEle.innerHTML = '';
        
        //防止没有摄像头播放
        var hasWebcamShow = false;
        for(var i = 0; i < data.length; i++) {
          //停止播放
          if(myWebcam[i] != null && myWebcam[i].player != null) {
            myWebcam[i].player.stop();
          }
          var eleId = "container" + i;
          var videoEleId = "video-container" + i;

          //记录数据， 用于刷新
          myWebcam[i] = {};
          myWebcam[i].webcamSerial = data[i].deviceSerial;
          myWebcam[i].playSsrc = data[i].playSsrc;
          myWebcam[i].ptz = data[i].ptz;   //是否有云台控制
          myWebcam[i].eleId = eleId;
          myWebcam[i].videoEleId = videoEleId;
          myWebcam[i].replayer = false; //true: 重新播放
          

          //添加元素
          var node = document.createElement("div");
          node.setAttribute('id', eleId);

          var videNode = document.createElement("canvas");
          videNode.setAttribute('id', videoEleId);
          videNode.setAttribute('width', that.width);
          videNode.setAttribute('height', that.height);
          videNode.setAttribute('style', "border-radius:10px;");
          videNode.setAttribute('onclick', "gbControlEvent(" + i + ")");
          node.appendChild(videNode);
          
          if(globalData.curWebcamSerial == '' || myWebcam[i].webcamSerial == globalData.curWebcamSerial) {
            node.setAttribute('style', "display:inline-block;");
            webcamListEle.appendChild(node);

            //记录当前播放监控序列号
            globalData.curWebcamSerial = myWebcam[i].webcamSerial;
            myWebcamShowId = i;
            hasWebcamShow = true;
          } else {
            node.setAttribute('style', "display:none;");
            webcamListEle.appendChild(node);
          }
          
        }
        //如何没有摄像头播放，则播放第一个
        if(hasWebcamShow == false && myWebcam.length > 0) {
          that.refreshWebcamShow();
        }


        that.loadFFmpegFun(that);
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });

    },
    loadFFmpegFun(that) {
      
      for(var i = 0; i < myWebcam.length; i++) {
        that.startFFmpegWorkerOne(that, i);
      }
      

    },
    startFFmpegWorkerOne(that, ind) {
      var toPlay = true;
      if(ind != 0) {
        toPlay = false;
      }
      var webcamOne = myWebcam[ind];
      var sipMediaServerIp = that.$GLOBAL.sipMediaServerIp;
      var sipMediaServerPort = that.$GLOBAL.sipMediaServerPort;
      var webcamSerial = webcamOne.webcamSerial;
      var playSsrc = webcamOne.playSsrc;
      var videoEleId = webcamOne.videoEleId;
      
      //发送播放指令
      that.sendWebcamCmd(webcamSerial, "play");

      //创建canvas渲染
      var renderer = that.getWebglScreen(that, videoEleId);
      myWebcam[ind].webglRender = renderer;

      var worker = new Worker('/js/worker.js', {name: videoEleId});
      myWebcam[ind].worker = worker;

      worker.onmessage = function (event) {
				if(typeof event.data === "string") {
					console.log('Received message ' + event.data);
					return;
				} 
        
				//视频流渲染canvas画面
        renderer.renderImg(that.width, that.height, event.data);
					
			}
			worker.onerror = function (event) {
				//console.log('ERROR: Line ' + event.lineno + ' in ' + event.filename + ': ' + event.message);
			}
				
      
			//worker.postMessage('Hello World');
			worker.postMessage({
        toPlay: toPlay,
				ind: ind,
        sipMediaServerIp: sipMediaServerIp,
        sipMediaServerPort: sipMediaServerPort,
				playSsrc: playSsrc,
				width: that.width,
				height: that.height,
			});
				
			// 主线程
			//worker.terminate();

    },
    
    //创建canvas渲染
    getWebglScreen(that, videoEleId) {
      var video = document.getElementById(videoEleId);

      var initialCanvas = (canvas, width, height) => {
        canvas.width = width;
        canvas.height = height;
        return new WebglScreen(canvas);
      };
      var renderer = initialCanvas(video,that.width,that.height);
      return renderer;
    },
    //发送国标摄像头命令
	  sendWebcamCmd(webcamSerial, type) {
      var msg = "";
      if(type == "play") {
        msg = "播放";
      } else if(type == "bye") {
        msg = "结束播放";
      }
      var param = "0";
      var resData = "?deviceSerial=" + webcamSerial + "&type=" + type + "&param=" + param;
      this.$httpApi.sendWebcamCmd(resData, function(d) {
        if(d.message == true) {
					console.log("正在" + msg);
				} else {
					console.log(msg + "失败");
				}
      }, function(xhr) {
			  console.log(xhr.responseText);
        console.log(msg + "异常");
		  });
    },
    //摄像头控制打开
    gbControlEvent(ind) {
      var sendData = {};
      sendData.srcIndex = ind;
      sendData.index = myWebcam.length + ind;
      sendData.switch = true;
      sendData.deviceSerial = myWebcam[ind].webcamSerial;
      sendData.playSsrc = myWebcam[ind].playSsrc;
      sendData.ptz = myWebcam[ind].ptz;

      myWebcam[ind].worker.postMessage('stop');
      Transfer.$emit('navDataScreen-index-gbControl', sendData);
      
    }
  },
  
};
</script>

<style lang="scss" scoped>
#centerRight2 {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .my-video {
    margin-top: 10px;
  }
  
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>