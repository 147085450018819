<template>
  <div>
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      height="360px"
      width="330px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart';

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  mounted () {
  },
  props: {
    cdata: {
      // type: Array,
      // default: () => [],
      type: Object,
      default: () => {},
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        var province = newData.province;
        var city = newData.city;
        var cityCoord = newData.cityCoord;
        var mapDatas = newData.mapDatas;
        
        
        this.options = {
          showLegendSymbol: true,
          //鼠标悬浮，展示相关指数tip
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: point => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            /*
              formatter: params => {
                return `<div style=""> ... </div>`
              }
            */
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
            },
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.22, //1.2,
            //top: '9%',
            //left: '10%',
            map: province,
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
              emphasis: {
                areaColor: '#00aeef',
              },
            },
          },
          series: [
            {
              name: '区域',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.22, //1.2,
              mapType: province, // 自定义扩展图表类型
              //top: '9%',
              //left: '10%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, .5)',
                  borderColor: 'rgba(0,242,252,.3)',
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc',
                },
                emphasis: {
                  areaColor: '#4f7fff',
                  borderColor: 'rgba(0,242,252,.6)',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc',
                },
              },
              label: {
                // formatter: params => `${params.name}`,
                formatter: function (params, ticket, callback) {
                  // console.log(params.name.length);
                  var allLen = params.name.length;
                  var itemLen = 6;

                  var retStr = params.name.substr(0, itemLen);
                  if(allLen > itemLen) {
                    retStr += "\n";
                    retStr += params.name.substr(itemLen);
                  }
                  return retStr;
                  // return params.name;
                },
                width: 2,
                show: true,
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef',
                },
                emphasis: {
                  textStyle: {
                    color: '#fff',
                  },
                },
              },
              data: mapDatas,
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: 18,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              rippleEffect: {
                period: 4,
                scale: 8, //2.5,
                brushType: 'stroke',
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  // color: '#99FBFE',
                  color: '#9900ff',
                  shadowBlur: 5,
                  shadowColor: '#fff',
                },
              },
              data: [{name: city, value: cityCoord}],
              tooltip: {
                trigger: 'item',
                textStyle: {
                  fontSize: 14,
                  lineHeight: 22,
                },
                position: point => {
                  // 固定在顶部
                  return [point[0] + 50, point[1] - 20];
                },
                // 如果需要自定义 tooltip样式，需要使用formatter
                formatter: function (params, ticket, callback) {
                 return '<div style="display:flex;flex-direction:row;align-items:center;">'
                        + '<span style="background-color:#9900ff;height:10px;width:10px;border-radius:5px;margin-right:5px;"></span>'
                        + '<span>' + params.name + '</span>'
                        + '</div>';
                }
              },
            },
          ],
        };
        
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    
  },
};
</script>
