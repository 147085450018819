<template>
    <div id="navDocument3" class="document" ref="navDocument3">
        <div  class="content" ref="content">
            <div style="text-align:center;">
                <img src="img/schCoopManual/1.png">
                <img src="img/schCoopManual/2.png">
                <img src="img/schCoopManual/3.png">
                <img src="img/schCoopManual/4.png">
                <img src="img/schCoopManual/5.png">
                <img src="img/schCoopManual/6.png">
                <img src="img/schCoopManual/7.png">
                <img src="img/schCoopManual/8.png">
                <img src="img/schCoopManual/9.png">
                <img src="img/schCoopManual/10.png">
                <img src="img/schCoopManual/11.png">
                <img src="img/schCoopManual/12.png">
                <img src="img/schCoopManual/13.png">
                <img src="img/schCoopManual/14.png">
                <img src="img/schCoopManual/15.png">
                <img src="img/schCoopManual/16.png">
                <img src="img/schCoopManual/17.png">
                <img src="img/schCoopManual/18.png">
                <img src="img/schCoopManual/19.png">
                <img src="img/schCoopManual/20.png">
                <img src="img/schCoopManual/21.png">
                <img src="img/schCoopManual/22.png">
                <img src="img/schCoopManual/23.png">
                <img src="img/schCoopManual/24.png">
                <img src="img/schCoopManual/25.png"  @load="imageLoad">
            </div>
        </div>
    </div>
</template>
<script>

import bScroll from 'better-scroll'

export default {
    data() {
        return {
            bscroll: null
        }
    },
    components: {

    },
    mounted() {
        // this.bscroll = new bScroll(this.$refs.navDocument, {
        //     probeType: 2,
        // });
        // this.bscroll.on("scroll", position=>{
        //     console.log(position);
        // });
        
    },
    beforeDestroy () {
        if(this.bscroll != null) {
            this.bscroll.disable();
            this.bscroll.destroy();
            this.bscroll = null;
        }
    },
    methods: {
        imageLoad() {
            if(this.bscroll != null) {
                this.bscroll.disable();
                this.bscroll.destroy();
                this.bscroll = null;
            }
            this.bscroll = new bScroll(this.$refs.navDocument3, {
                probeType: 2,
                disableMouse: false,//启用鼠标拖动
                disableTouch: false//启用手指触摸
            });
            // this.bscroll.on("scroll", position=>{
            //     console.log("position=" + position);
            // });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/document.scss';
.document {
    img {
        zoom:0.7;
    }
}
</style>
