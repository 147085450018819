<template>
    <div id="deviceFun">
        <dv-loading v-if="loading" style="position:absolute;height:60%;z-index:99;">Loading...</dv-loading>
        <div :class="contentBox">
            <dv-border-box-12>
                <deviceFunLight />
            </dv-border-box-12>
            <dv-border-box-12 :style="{display:deviceFunFanDisplay}">
                <deviceFunFan v-if="deviceFunFanShow"/>
            </dv-border-box-12>
            <dv-border-box-12>
                <deviceFunSystem />
            </dv-border-box-12>
        </div>
    </div>
</template>

<script>

import Transfer from "../utils/transfer.js";
import deviceFunLight from "./deviceFunLight";
import deviceFunFan from "./deviceFunFan";
import deviceFunSystem from "./deviceFunSystem";

export default {
    data() {
        return {
            contentBox: "content-box",
            curMachine: '',
            curOnline: 0, //0:离线，1：在线
            curStyleSize: null,  //1：智灵钻， 2：智立方， 3：智立方L， 4：单面屏风， 5：双面屏风， 6：智灵宝， 7：工程模组， 8：集装箱
            curProtocolType: null, //1：家用机协议，2：工程模组协议
            loading: true,
            deviceFunFanDisplay: "block",
            deviceFunFanShow: true
        }
    },
    components: {
        deviceFunLight,
        deviceFunFan,
        deviceFunSystem,
    },
    mounted() {
        Transfer.$on('deviceFun', (msg) => {
            //console.log(JSON.stringify(msg));
            Transfer.$off('deviceFun');

            //家用机 根据款式配置数量  防止有时数量刷新错误
            Transfer.$emit('deviceFunLightStyle', msg);
            Transfer.$emit('deviceFunFanStyle', msg);
            Transfer.$emit('deviceFunSystemStyle', msg);
            
            // console.log(JSON.stringify(msg));
            this.loading = false;

            this.curMachine = msg.curMachine;
            this.curOnline = msg.curOnline;
            this.curStyleSize = msg.curStyleSize;
            this.curProtocolType = msg.curProtocolType;
            



            //1:一代机，2：二代机
            if(this.curProtocolType == 2) {
                this.getModuleLightValue();
                this.getModuleFanValue();
                if(this.curStyleSize == 7 || this.curStyleSize == 8) {
                    //工程模组
                    this.getModulePumpStatus();
                    this.getMachineModuleConfigList();
                } else {
                    //家用机
                    //this.getMachineEquipDetail();
                    this.getModuleControl();
                }
            } else {
                //一代机 家用机 不确定控制方式
                this.contentBox = "content-box-no-fan";
                this.deviceFunFanDisplay = "none";
                this.deviceFunFanShow = false;
                
                this.getMachineDetail();
                //this.getMachineEquipDetail();
            }
        });
    },
    beforeDestroy () {

    },
    methods: {
        //一代机 家用机
        getMachineDetail() {
            if(this.curMachine == '') return;

            let that = this;
            //获取设备详情
            var curMachineId = this.curMachine;
            var resData = curMachineId;
            this.$httpApi.getMachineDetail(resData, function(data) {
                // console.log(JSON.stringify(data));
                if(data == null) return;

                //生长灯
                var lightData = [];
                lightData[0] = {};
                lightData[0].id = 1;
                lightData[0].switch = (data.controlLight1 == null ? false : (data.controlLight1 == 1 ? true : false));
                lightData[0].value = (data.illumination0 == null ? 0 : data.illumination0);
                lightData[1] = {};
                lightData[1].id = 2;
                lightData[1].switch = (data.controlLight2 == null ? false : (data.controlLight2 == 1 ? true : false));
                lightData[1].value = (data.illumination1 == null ? 0 : data.illumination1);
                lightData[2] = {};
                lightData[2].id = 3;
                lightData[2].switch = (data.controlLight3 == null ? false : (data.controlLight3 == 1 ? true : false));
                lightData[2].value = (data.illumination2 == null ? 0 : data.illumination2);
                lightData[3] = {};
                lightData[3].id = 4;
                lightData[3].switch = (data.controlLight4 == null ? false : (data.controlLight4 == 1 ? true : false));
                lightData[3].value = (data.illumination3 == null ? 0 : data.illumination3);
                lightData[4] = {};
                lightData[4].id = 5;
                lightData[4].switch = (data.controlLight5 == null ? false : (data.controlLight5 == 1 ? true : false));
                lightData[4].value = (data.illumination4 == null ? 0 : data.illumination4);
                lightData[5] = {};
                lightData[5].id = 6;
                lightData[5].switch = (data.controlLight6 == null ? false : (data.controlLight6 == 1 ? true : false));
                lightData[5].value = (data.illumination5 == null ? 0 : data.illumination5);

                //风扇
                var fanData = [];
                fanData[0] = {};
                fanData[0].id = 1;
                fanData[0].switch = (data.controlFan1 == null ? false : (data.controlFan1 == 1 ? true : false));
                fanData[0].value = (data.fanSpeed0 == null ? 0 : data.fanSpeed0);
                fanData[1] = {};
                fanData[1].id = 2;
                fanData[1].switch = (data.controlFan2 == null ? false : (data.controlFan2 == 1 ? true : false));
                fanData[1].value = (data.fanSpeed1 == null ? 0 : data.fanSpeed1);
                fanData[2] = {};
                fanData[2].id = 3;
                fanData[2].switch = (data.controlFan3 == null ? false : (data.controlFan3 == 1 ? true : false));
                fanData[2].value = (data.fanSpeed2 == null ? 0 : data.fanSpeed2);
                fanData[3] = {};
                fanData[3].id = 4;
                fanData[3].switch = (data.controlFan4 == null ? false : (data.controlFan4 == 1 ? true : false));
                fanData[3].value = (data.fanSpeed3 == null ? 0 : data.fanSpeed3);
                fanData[4] = {};
                fanData[4].id = 5;
                fanData[4].switch = (data.controlFan5 == null ? false : (data.controlFan5 == 1 ? true : false));
                fanData[4].value = (data.fanSpeed4 == null ? 0 : data.fanSpeed4);
                fanData[5] = {};
                fanData[5].id = 6;
                fanData[5].switch = (data.controlFan6 == null ? false : (data.controlFan6 == 1 ? true : false));
                fanData[5].value = (data.fanSpeed5 == null ? 0 : data.fanSpeed5);
                
                //水泵
                var pumpData = [];
                pumpData[0] = {};
                pumpData[0].id = 1;
                pumpData[0].switch = (data.controlPump == null ? false : (data.controlPump == 1 ? true : false));
                pumpData[0].name = "水泵";
                var controlWind = (data.controlWind == null ? false : (data.controlWind == 1 ? true : false));
                
                var msg = {};
                msg.curMachine = that.curMachine;
                msg.curOnline = that.curOnline;
                msg.curStyleSize = that.curStyleSize;
                msg.curProtocolType = that.curProtocolType;
                msg.curData = lightData;
                Transfer.$emit('deviceFunLight', msg);

                msg.curData = fanData;
                Transfer.$emit('deviceFunFan', msg);
                
                msg.curData = pumpData;
                msg.controlWind = controlWind;
                Transfer.$emit('deviceFunSystem', msg);
            
            }, function(xhr) {
                console.log(xhr.responseText);
            });

        },
        //一代机  获取设备外设
        getMachineEquipDetail() {
            if(this.curMachine == '') return;
            
            //获取设备详情
            var curMachineId = this.curMachine;
            var reqData = "?machineId=" + curMachineId;
            this.$httpApi.getMachineEquipDetail(reqData, function(data) {
                // console.log(JSON.stringify(data));
                if(data.result == true && data.data != null) {
                    var lightPrefix = "szd";
					var fanPrefix = "fs";
                    var lightPrefixList = [];
                    var fanPrefixList = [];
                    var lightNum = 0;
                    var fanNum = 0;
                    for(var j = 0; j < data.data.length; j++) {
                        var itemD = data.data[j];
                        var index = itemD.equipDetailCode.indexOf(lightPrefix);
                        if(index != undefined && index == 0 && lightPrefixList.indexOf(itemD.equipDetailCode) == -1) {
                            lightPrefixList.push(itemD.equipDetailCode);
                            lightNum++;
                        }
                        index = itemD.equipDetailCode.indexOf(fanPrefix);
                        if(index != undefined && index == 0 && fanPrefixList.indexOf(itemD.equipDetailCode) == -1) {
                            fanPrefixList.push(itemD.equipDetailCode);
                            fanNum++;
                        }
                    }
                    
                    Transfer.$emit('deviceFunLightNum', lightNum);
                    Transfer.$emit('deviceFunFanNum', fanNum);
                }
            }, function(xhr) {
                console.log(xhr.responseText);
            });
        },
        //二代机  生长灯亮度
        getModuleLightValue() {
            if(this.curMachine == '') return;

            let that = this;
            //获取设备详情
            var curMachineId = this.curMachine;
            var reqData = "?machineId=" + curMachineId;
            this.$httpApi.getModuleLightValue(reqData, function(d) {
                // console.log(JSON.stringify(d));
                if(d.message == null || d.message.length <= 0) return;

                var lightDataArr = [];
                var data = d.message;
                for(var i = 0; i < data.length; i++) {
                    var msg = data[i];
                    
                    //生长灯
                    var lightData = [];
                    lightData[0] = {};
                    lightData[0].id = 1;
                    lightData[0].switch = (msg.light1Value == null ? false : (msg.light1Value > 0 ? true : false));
                    lightData[0].value = (msg.light1Value == null ? 0 : msg.light1Value);
                    lightData[1] = {};
                    lightData[1].id = 2;
                    lightData[1].switch = (msg.light2Value == null ? false : (msg.light2Value > 0 ? true : false));
                    lightData[1].value = (msg.light2Value == null ? 0 : msg.light2Value);
                    lightData[2] = {};
                    lightData[2].id = 3;
                    lightData[2].switch = (msg.light3Value == null ? false : (msg.light3Value > 0 ? true : false));
                    lightData[2].value = (msg.light3Value == null ? 0 : msg.light3Value);
                    lightData[3] = {};
                    lightData[3].id = 4;
                    lightData[3].switch = (msg.light4Value == null ? false : (msg.light4Value > 0 ? true : false));
                    lightData[3].value = (msg.light4Value == null ? 0 : msg.light4Value);
                    lightData[4] = {};
                    lightData[4].id = 5;
                    lightData[4].switch = (msg.light5Value == null ? false : (msg.light5Value > 0 ? true : false));
                    lightData[4].value = (msg.light5Value == null ? 0 : msg.light5Value);
                    lightData[5] = {};
                    lightData[5].id = 6;
                    lightData[5].switch = (msg.light6Value == null ? false : (msg.light6Value > 0 ? true : false));
                    lightData[5].value = (msg.light6Value == null ? 0 : msg.light6Value);
                    lightData[6] = {};
                    lightData[6].id = 7;
                    lightData[6].switch = (msg.light7Value == null ? false : (msg.light7Value > 0 ? true : false));
                    lightData[6].value = (msg.light7Value == null ? 0 : msg.light7Value);
                    lightData[7] = {};
                    lightData[7].id = 8;
                    lightData[7].switch = (msg.light8Value == null ? false : (msg.light8Value > 0 ? true : false));
                    lightData[7].value = (msg.light8Value == null ? 0 : msg.light8Value);
                    lightData[8] = {};
                    lightData[8].id = 9;
                    lightData[8].switch = (msg.light9Value == null ? false : (msg.light9Value > 0 ? true : false));
                    lightData[8].value = (msg.light9Value == null ? 0 : msg.light9Value);
                    lightData[9] = {};
                    lightData[9].id = 10;
                    lightData[9].switch = (msg.light10Value == null ? false : (msg.light10Value > 0 ? true : false));
                    lightData[9].value = (msg.light10Value == null ? 0 : msg.light10Value);

                    
                    lightDataArr[i] = {};
                    lightDataArr[i].id = msg.rackNo;
                    lightDataArr[i].data = lightData;
                }
                
                lightDataArr.sort(function(a, b) {
                    return a.id > b.id ? 1 : -1;
                });
                
                var msg = {};
                msg.curMachine = that.curMachine;
                msg.curOnline = that.curOnline;
                msg.curStyleSize = that.curStyleSize;
                msg.curProtocolType = that.curProtocolType;
                msg.curData = lightDataArr;
                Transfer.$emit('deviceFunLight', msg);
                
            
            }, function(xhr) {
                console.log(xhr.responseText);
            });

        },
        //二代机  风扇转速
        getModuleFanValue() {
            if(this.curMachine == '') return;

            let that = this;
            //获取设备详情
            var curMachineId = this.curMachine;
            var reqData = "?machineId=" + curMachineId;
            this.$httpApi.getModuleFanValue(reqData, function(d) {
                // console.log(JSON.stringify(d));
                if(d.message == null || d.message.length <= 0) return;

                var fanDataArr = [];
                var data = d.message;
                for(var i = 0; i < data.length; i++) {
                    var msg = data[i];

                    //生长灯
                    var fanData = [];
                    fanData[0] = {};
                    fanData[0].id = 1;
                    fanData[0].switch = (msg.fan1Value == null ? false : (msg.fan1Value > 0 ? true : false));
                    fanData[0].value = (msg.fan1Value == null ? 0 : msg.fan1Value);
                    fanData[1] = {};
                    fanData[1].id = 2;
                    fanData[1].switch = (msg.fan2Value == null ? false : (msg.fan2Value > 0 ? true : false));
                    fanData[1].value = (msg.fan2Value == null ? 0 : msg.fan2Value);
                    fanData[2] = {};
                    fanData[2].id = 3;
                    fanData[2].switch = (msg.fan3Value == null ? false : (msg.fan3Value > 0 ? true : false));
                    fanData[2].value = (msg.fan3Value == null ? 0 : msg.fan3Value);
                    fanData[3] = {};
                    fanData[3].id = 4;
                    fanData[3].switch = (msg.fan4Value == null ? false : (msg.fan4Value > 0 ? true : false));
                    fanData[3].value = (msg.fan4Value == null ? 0 : msg.fan4Value);
                    fanData[4] = {};
                    fanData[4].id = 5;
                    fanData[4].switch = (msg.fan5Value == null ? false : (msg.fan5Value > 0 ? true : false));
                    fanData[4].value = (msg.fan5Value == null ? 0 : msg.fan5Value);
                    fanData[5] = {};
                    fanData[5].id = 6;
                    fanData[5].switch = (msg.fan6Value == null ? false : (msg.fan6Value > 0 ? true : false));
                    fanData[5].value = (msg.fan6Value == null ? 0 : msg.fan6Value);
                    fanData[6] = {};
                    fanData[6].id = 7;
                    fanData[6].switch = (msg.fan7Value == null ? false : (msg.fan7Value > 0 ? true : false));
                    fanData[6].value = (msg.fan7Value == null ? 0 : msg.fan7Value);
                    fanData[7] = {};
                    fanData[7].id = 8;
                    fanData[7].switch = (msg.fan8Value == null ? false : (msg.fan8Value > 0 ? true : false));
                    fanData[7].value = (msg.fan8Value == null ? 0 : msg.fan8Value);
                    fanData[8] = {};
                    fanData[8].id = 9;
                    fanData[8].switch = (msg.fan9Value == null ? false : (msg.fan9Value > 0 ? true : false));
                    fanData[8].value = (msg.fan9Value == null ? 0 : msg.fan9Value);
                    fanData[9] = {};
                    fanData[9].id = 10;
                    fanData[9].switch = (msg.fan10Value == null ? false : (msg.fan10Value > 0 ? true : false));
                    fanData[9].value = (msg.fan10Value == null ? 0 : msg.fan10Value);

                    
                    fanDataArr[i] = {};
                    fanDataArr[i].id = msg.rackNo;
                    fanDataArr[i].data = fanData;
                }
                
                fanDataArr.sort(function(a, b) {
                    return a.id > b.id ? 1 : -1;
                });
                
                var msg = {};
                msg.curMachine = that.curMachine;
                msg.curOnline = that.curOnline;
                msg.curStyleSize = that.curStyleSize;
                msg.curProtocolType = that.curProtocolType;
                msg.curData = fanDataArr;
                Transfer.$emit('deviceFunFan', msg);
                
            
            }, function(xhr) {
                console.log(xhr.responseText);
            });

        },
        //二代机 控制状态  家用机
        getModuleControl() {
            if(this.curMachine == '') return;

            let that = this;
            //获取设备详情
            var curMachineId = this.curMachine;
            var reqData = "?machineId=" + curMachineId;
            this.$httpApi.getModuleControl(reqData, function(data) {
                // console.log(JSON.stringify(data));
                //成功
                if(data.message == null || data.message.length <= 0) {
                    return;
                }
                var msg = data.message;
                
                //水泵
                var pumpData = [];
                pumpData[0] = {};
                pumpData[0].id = 1;
                pumpData[0].switch = (msg.pumpSwitch == null ? false : (msg.pumpSwitch == 2 ? true : false));
                pumpData[0].name = "水泵";

                var msg = {};
                msg.curMachine = that.curMachine;
                msg.curOnline = that.curOnline;
                msg.curStyleSize = that.curStyleSize;
                msg.curProtocolType = that.curProtocolType;
                msg.curData = pumpData;
                Transfer.$emit('deviceFunSystem', msg);

            });

        },
        //获取工程模组水泵状态
        getModulePumpStatus() {
            let that = this;
            var curMachineId = this.curMachine;
            var reqData = "?machineId=" + curMachineId;
            //工程模组
            this.$httpApi.getModulePumpStatus(reqData, function(data) {
                // console.log(JSON.stringify(data));
                if(data == null) return;
                var pumpData = [];
                pumpData[0] = {};
                pumpData[0].id = 1;
                pumpData[0].switch = (data.pump1 == null ? false : (data.pump1 == 2 ? true : false));
                pumpData[0].name = "水泵1";
                pumpData[1] = {};
                pumpData[1].id = 2;
                pumpData[1].switch = (data.pump2 == null ? false : (data.pump2 == 2 ? true : false));
                pumpData[1].name = "水泵2";
                pumpData[2] = {};
                pumpData[2].id = 3;
                pumpData[2].switch = (data.pump3 == null ? false : (data.pump3 == 2 ? true : false));
                pumpData[2].name = "水泵3";
                pumpData[3] = {};
                pumpData[3].id = 4;
                pumpData[3].switch = (data.pump4 == null ? false : (data.pump4 == 2 ? true : false));
                pumpData[3].name = "水泵4";
                pumpData[4] = {};
                pumpData[4].id = 5;
                pumpData[4].switch = (data.pump5 == null ? false : (data.pump5 == 2 ? true : false));
                pumpData[4].name = "水泵5";
                pumpData[5] = {};
                pumpData[5].id = 6;
                pumpData[5].switch = (data.pump6 == null ? false : (data.pump6 == 2 ? true : false));
                pumpData[5].name = "水泵6";
                pumpData[6] = {};
                pumpData[6].id = 7;
                pumpData[6].switch = (data.pump7 == null ? false : (data.pump7 == 2 ? true : false));
                pumpData[6].name = "水泵7";
                pumpData[7] = {};
                pumpData[7].id = 8;
                pumpData[7].switch = (data.pump8 == null ? false : (data.pump8 == 2 ? true : false));
                pumpData[7].name = "水泵8";
                pumpData[8] = {};
                pumpData[8].id = 9;
                pumpData[8].switch = (data.pump9 == null ? false : (data.pump9 == 2 ? true : false));
                pumpData[8].name = "水泵9";
                pumpData[9] = {};
                pumpData[9].id = 10;
                pumpData[9].switch = (data.pump10 == null ? false : (data.pump10 == 2 ? true : false));
                pumpData[9].name = "水泵10";
                pumpData[10] = {};
                pumpData[10].id = 11;
                pumpData[10].switch = (data.pump11 == null ? false : (data.pump11 == 2 ? true : false));
                pumpData[10].name = "主水泵1";
                pumpData[11] = {};
                pumpData[11].id = 12;
                pumpData[11].switch = (data.pump12 == null ? false : (data.pump12 == 2 ? true : false));
                pumpData[11].name = "主水泵2";

                var msg = {};
                msg.curMachine = that.curMachine;
                msg.curOnline = that.curOnline;
                msg.curStyleSize = that.curStyleSize;
                msg.curProtocolType = that.curProtocolType;
                msg.curData = pumpData;
                Transfer.$emit('deviceFunSystem', msg);

            });
        },

        //获取工程模组配置列表
        getMachineModuleConfigList() {
            let that = this;
            var curMachineId = this.curMachine;
            var reqData = "?machineId=" + curMachineId;
            //工程模组
            this.$httpApi.getMachineModuleConfigList(reqData, function(d) {
                // console.log(JSON.stringify(d));
                if(d.message == null || d.message.length == 0) {
                    // mui.toast("获取失败");
                    return;
                }
                var data = d.message;
                
                var layerFanExists = false;
                var moduleNum = data.length - 1;
                var layerNumArr = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
                var lightExistsArr = [false, false, false, false, false, false, false, false, false, false];
                var fanExistsArr = [false, false, false, false, false, false, false, false, false, false];
                var pumpExistsArr = [false, false, false, false, false, false, false, false, false, false, false, false];
                var ind = 0;
                for(var i = 0; i < data.length; i++) {
                    var item = data[i];

                    if(item.moduleIndex == null || item.moduleIndex < 0 || item.moduleIndex > 10) continue;
                    ind = item.moduleIndex;
                    if(item.moduleIndex == 0) {
                        pumpExistsArr[10] = (item.pump1 == 1 ? true : false);
                        pumpExistsArr[11] = (item.pump2 == 1 ? true : false);
                    } else {
                        layerNumArr[ind - 1] = item.layerNum;
                        lightExistsArr[ind - 1] = true;
                        fanExistsArr[ind - 1] = (item.layerFan == 1 ? true : false);
                        pumpExistsArr[ind - 1] = (item.pump == 1 ? true : false);
                        if(item.layerFan) {
                            layerFanExists = true;
                        }
                    }
                    
                }

                
                var msg = {};
                msg.moduleNum = moduleNum;
                msg.layerNumArr = layerNumArr;
                msg.lightExistsArr = lightExistsArr;
                Transfer.$emit('deviceFunLightNum', msg);

                if(layerFanExists) {
                    msg = {};
                    msg.moduleNum = moduleNum;
                    msg.layerNumArr = layerNumArr;
                    msg.fanExistsArr = fanExistsArr;
                    Transfer.$emit('deviceFunFanNum', msg);
                } else {
                    that.contentBox = "content-box-no-fan";
                    that.deviceFunFanDisplay = "none";
                    that.deviceFunFanShow = false;
                }
                

                msg = {};
                msg.pumpExistsArr = pumpExistsArr;
                Transfer.$emit('deviceFunSystemNum', msg);


            });
        }
    }

}

</script>
<style lang="scss" scoped>

#deviceFun {
    .content-box {
        display: grid;
        grid-template-columns:  6.4fr 6.4fr 3.2fr;
    }
    .content-box-no-fan {
        display: grid;
        grid-template-columns: 6.4fr 3.2fr 6.4fr;
    }
}


</style>
