<template>
  <div id="centerRight3">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <!-- <span class="fs-xl text mx-2">水泵</span> -->
          <span v-if="pumpNum > 1" class="fs-xl text mx-2">水泵: {{pumpNum}} - {{curPumpNo}}</span>
          <span v-else class="fs-xl text mx-2">水泵</span>
          <dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      
      <div class="d-flex jc-center" style="margin-top:45px;">
        <dv-decoration-9 style="width:260px;height:260px;">{{pumpSwitchShow}}</dv-decoration-9>
      </div>
    </div>
  </div>
</template>

<script>

import Transfer from "../utils/transfer";

//工程模组水泵状态
var modulePumpArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
export default {
  data() {
    return {
      pumpSwitchShow: "关闭",
      pumpNum: 1,
      curPumpNo: 1,
    }
  },
  components: {
    
  },
	mounted(){
    var globalData = this.$store.state.globalData;
    // this.pumpNum = globalData.pumpNum;
    // this.curPumpNo = globalData.curPumpNo;
    // this.refreshMachineView(globalData);
    
    Transfer.$on('centerRight3', (msg) => {
      // console.log(msg);
      this.pumpNum = globalData.pumpNum;
      this.curPumpNo = globalData.curPumpNo;

      this.refreshMachineView(globalData);
    });
    Transfer.$on('centerRight3-view', (msg) => {
      // console.log(JSON.stringify(msg));
      //刷新水泵编号显示
      this.pumpNum = globalData.pumpNum;
      this.curPumpNo = globalData.curPumpNo;
      this.refreshView(globalData);
      
    });
	},
  beforeDestroy () {
    
  },
  methods: {
    refreshView(globalData) {
      var pumpIndex = globalData.pumpList[this.curPumpNo-1];
      this.pumpSwitchShow = (modulePumpArr[pumpIndex - 1] == 1 ? "运行" : "关闭");
    },
    refreshMachineView(globalData) {
      if(globalData.curProtocolType == 2) {
        //二代机
        this.getModulePumpStatus(globalData);
      } else {
        // console.log(globalData.curPumpStatus);
        this.pumpSwitchShow = (globalData.curPumpStatus == 1 ? "运行" : "关闭");
      }
    },
    getModulePumpStatus(globalData) {
      var that = this;
      var curMachineId = globalData.curMachine;
      var resData = "?machineId=" + curMachineId;

      if(globalData.curStyleSize == 7 || globalData.curStyleSize == 8) {
        //工程模组
        this.$httpApi.getModulePumpStatus(resData, function(data) {
          // console.log(JSON.stringify(data));
          if(data == null) return;
          modulePumpArr[0] = (data.pump1 == 2 ? 1 : 0);
          modulePumpArr[1] = (data.pump2 == 2 ? 1 : 0);
          modulePumpArr[2] = (data.pump3 == 2 ? 1 : 0);
          modulePumpArr[3] = (data.pump4 == 2 ? 1 : 0);
          modulePumpArr[4] = (data.pump5 == 2 ? 1 : 0);
          modulePumpArr[5] = (data.pump6 == 2 ? 1 : 0);
          modulePumpArr[6] = (data.pump7 == 2 ? 1 : 0);
          modulePumpArr[7] = (data.pump8 == 2 ? 1 : 0);
          modulePumpArr[8] = (data.pump9 == 2 ? 1 : 0);
          modulePumpArr[9] = (data.pump10 == 2 ? 1 : 0);
          modulePumpArr[10] = (data.pump11 == 2 ? 1 : 0);
          modulePumpArr[11] = (data.pump12 == 2 ? 1 : 0);
          
          that.refreshView(globalData);
        });
      } else {
        this.$httpApi.getModuleControl(resData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          if(data.message == null || data.message.length <= 0) {
						return;
					}
          var msg = data.message;
          that.pumpSwitchShow = (msg.pumpSwitch == 2 ? "运行" : "关闭");
        });
      }
    }
  }
	

};
</script>

<style lang="scss" scoped>
#centerRight3 {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .my-video {
    margin-top: 10px;
  }
  
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>