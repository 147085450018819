<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        province: '河北',
        city: '石家庄市',
        cityCoord: ['114.522081844', '38.0489583146'],
        //名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
        mapDatas: [
          {name: '石家庄市', value: 10},
          {name: '唐山市', value: 9},
          {name: '秦皇岛市', value: 8},
          {name: '邯郸市', value: 7},
          {name: '邢台市', value: 6,},
          {name: '保定市', value: 5},
          {name: '张家口市', value: 4},
          {name: '承德市', value: 3},
          {name: '沧州市', value: 2},
          {name: '廊坊市', value: 1},
          {name: '衡水市', value: 0},
        ]
      }
      
    }
  },
  components: {
    Chart,
  },
  mounted () {
    var globalData = this.$store.state.globalData;

    this.cdata.province = globalData.province;
    this.cdata.city = globalData.region;
    this.cdata.cityCoord = [globalData.longitude, globalData.latitude, 0];

    var curMapDatas = [];
    if(globalData.regionList != null && globalData.regionList.length > 0) {
      for(var i = 0; i < globalData.regionList.length; i++) {
        curMapDatas[i] = {};
        curMapDatas[i].name = globalData.regionList[i];
        curMapDatas[i].value = i;
      }
    }
    this.cdata.mapDatas = curMapDatas;
    
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>