<template>
    <div id="navDocument2" class="document" ref="navDocument2">
        <div  class="content" ref="content">
            <h1>上蔬源-校园智慧农场——三产融合劳动课程教学大纲（1-9年级）</h1>
            <h2>&nbsp;</h2>
            <div style="display:flex;flex-direction:column;align-items:center;">
                <div style="display:flex;flex-direction:column;align-items:flex-start;">
                    <img src="img/courseOutline/1.png">
                    <img src="img/courseOutline/2.png">
                    <img src="img/courseOutline/3.png">
                    <img src="img/courseOutline/4.png" @load="imageLoad">
                    <img src="img/courseOutline/5.png" @load="imageLoad">

                    <p style="margin-top:10px;">注：上蔬源-智慧农场——劳动课程教学大纲依据</p>
                    <p>义务教育劳动课程标准（2022年版）</p>
                    <p style="margin-top:50px;">上蔬源-义务教育课程研发中心</p>
                    <p>2022年5月20日</p>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>

import bScroll from 'better-scroll'

export default {
    data() {
        return {
            bscroll: null
        }
    },
    components: {

    },
    mounted() {
        // this.bscroll = new bScroll(this.$refs.navDocument, {
        //     probeType: 2,
        // });
        // this.bscroll.on("scroll", position=>{
        //     console.log(position);
        // });
        
    },
    beforeDestroy () {
        if(this.bscroll != null) {
            this.bscroll.disable();
            this.bscroll.destroy();
            this.bscroll = null;
        }
    },
    methods: {
        imageLoad() {
            if(this.bscroll != null) {
                this.bscroll.disable();
                this.bscroll.destroy();
                this.bscroll = null;
            }
            this.bscroll = new bScroll(this.$refs.navDocument2, {
                probeType: 2,
                disableMouse: false,//启用鼠标拖动
                disableTouch: false//启用手指触摸
            });
            // this.bscroll.on("scroll", position=>{
            //     console.log("position=" + position);
            // });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/document.scss';

</style>
