<template>
  <div id="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <!-- <span class="fs-xl text mx-2">环境传感器</span> -->
          <span v-if="envSensorNum > 1" class="fs-xl text mx-2">环境传感器: {{envSensorNum}} - {{curEnvSensorNo}}</span>
          <span v-else class="fs-xl text mx-2">环境传感器</span>
        </div>
      </div>
      <div>
        <BottomLeftChart />
      </div>
    </div>
  </div>
</template>

<script>
import BottomLeftChart from '@/components/echart/bottom/bottomLeftChart'
import Transfer from "../utils/transfer.js";

export default {
  data() {
    return {
      timing: null,
      envSensorNum: 1,
      curEnvSensorNo: 1
    }
  },
  components: {
    BottomLeftChart
  },
  mounted(){
    var globalData = this.$store.state.globalData;
    // this.envSensorNum = globalData.envSensorNum;
    // this.curEnvSensorNo = globalData.curEnvSensorNo;
    // if(globalData.curProtocolType == 2 && this.envSensorNum > 1) {
    //   this.changeTiming();
    // }
    
    Transfer.$on('bottomLeft', (msg) => {
      this.envSensorNum = globalData.envSensorNum;
      this.curEnvSensorNo = globalData.curEnvSensorNo;
      
      if(globalData.curProtocolType == 2 && this.envSensorNum > 1) {
        //停止定时器
        clearInterval(this.timing);
        this.timing = null;
        //重新启动定时器
        this.changeTiming();
      }

      // console.log(msg);
      Transfer.$emit('buttomLeft-buttomLeft','msg-buttomLeft-buttomLeft');
    });
  },
  beforeDestroy () {
    clearInterval(this.timing);
    this.timing = null;
  },
  methods: {
    changeTiming() {
      var globalData = this.$store.state.globalData;
      this.timing = setInterval(() => {
        if(globalData.curProtocolType == 2 && this.envSensorNum > 1) {
          if(this.curEnvSensorNo >= this.envSensorNum) {
            this.curEnvSensorNo = 1;
          } else {
            this.curEnvSensorNo += 1;
          }

          globalData.curEnvSensorNo = this.curEnvSensorNo;
          Transfer.$emit('buttomLeft-buttomLeft-child','msg-buttomLeft-buttomLeft-child');
        }

      }, this.$GLOBAL.deviceChildShowTiming);
      
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 520px;
$box-width: 100%;
#bottomLeft {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
